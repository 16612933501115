.sub-header {
  background-color: #f8f8fa;
  border-bottom: 1px solid lightgray;
}

.sub-header-tab {
  background-color: transparent
}

.sub-header__column-left {
  box-sizing: border-box;
  width: 50%;
  float: left;
  padding: 20px;
}

.sub-header__column-right {
  box-sizing: border-box;
  width: 50%;
  float: left;
  padding: 0 20px;
}

.sub-header__label {
  float: left;
  margin-right: 4px;
}

.sub-header__title {
  float: left;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.sub-header__create-button {
  box-sizing: border-box;
  min-width: 100px;
  margin: 15px 8px;
  float: right;
}
