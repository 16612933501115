@import '~Styles/variables';

area-details {

  input[type=checkbox] {
    margin: 0 5px;
  }

  div.form-section {
    width: 500px;

    ul {
      background: white;
      list-style: none;
      margin: 0;
      li {
        padding: 8px;
        border-bottom: 1px solid @reportBorderColor;

        &:hover {
          background-color: @hoverItemBackgroundColor;
        }
      }
    }
  }

  table {
    width: 100%;
    margin-top: 5px;

    .area-office-column {
      text-align: center;
    }

    tr {

      .inactive {
        color: @inactiveFontColor;
      }

      td {
        padding: 5px;
      }

    }

  }
}