#batch-memo {

  .nav-tabs {
    .disabled {
      color: darkgray;
    }
  }

  .content {
    padding: 10px;

    .memo-primary-params-table {
      margin-top: 10px;
      margin-bottom: 10px;
      .param-label {
        font-weight: bold;
        width: 105px;
      }
      .param-value {
        padding: 2px;
        width: 500px;
        .selectize-input {
          padding: 5px;
        }
        input {
          padding: 5px;
          width: 98%;
        }
      }
    }

    .filters {
      display: flex;
      justify-content: flex-end;

      > * {
        margin: 10px;
      }

      .status {
        font-weight: bold;
      }

      button {
        height: 30px;
      }
    }

    .tab-content {
      overflow: visible;
    }
  }
}