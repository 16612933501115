customer-risk-profile-view {

  form {
    margin: 0;
  }

  div.form-section {
    box-sizing: border-box;
    width: 500px;
    float: left;
    padding: 0 40px 0 10px;

    .flex-container {
      display: flex;
      align-items: center;
      place-items: flex-end;

      button {
        margin: 9px 0 8px 10px;
      }
    }
  }

  .buttons {
    float: right;
    margin: 20px;
  }

  .risk-profile-entry__table {
    box-sizing: border-box;
    margin: 20px;
    width: calc(100% - 40px);

    input, select {
      margin: 0;
    }

    .no-column-header {
      width: 40px;
    }
  }

  .table--small {
    width: auto;
    margin: 20px;
  }
}
