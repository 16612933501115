@media print {
  customer-profile {
    display: block;

    // Remove border from all inputs when printing
    textarea,
    select,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"] {
      border: none;
    }

    // Hide lines at the end of page
    .profile-body .tab-content {
      border-bottom: none;
    }

    // Display header with print metadata
    print-header {
      display: block;
    }

    // Hide styling of appended fields (Feet, Inch etc.)
    span.add-on.glue-appended {
      border: none;
      background: transparent;
    }

    // Remove placeholders from print
    input::-webkit-input-placeholder { /* WebKit browsers */
      color: transparent;
    }

    // Don't show date-format (e.g. 'dd.mm.rrrr') for empty field
    input[type="date"].ng-empty {
      color: transparent !important;
    }
  }
}
