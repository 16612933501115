@import '../../../../style/variables.less';

exchange-course-list {
  display: block;

  &.breadcrumb-exchange {
    flex-grow: 3;
  }
  
  .exchange {
    display: flex;
    justify-content: center;

    &-item {
      align-items: center;
      background-color: @forexWidgetColor;
      border-radius: 50%;
      box-sizing: border-box;
      color: white;
      display: flex;
      font-weight: bold;
      height: 50px;
      justify-content: center;
      margin: 5px 10px;
      position: relative;
      width: 50px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}