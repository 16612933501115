.administration-branches {
  .active {
    color: green;
  }

  .pending {
    color: orange;
  }

  .closed {
    color: red;
  }
}
