risk-assessment-view {

  .buttons {
    float: right;
    margin: 20px;
  }

  .risk-assessment__table {
    box-sizing: border-box;
    margin: 20px;
    width: calc(100% - 40px);
  }
}