deposit-manual-rollover {

  .range-input {
    width: 100%;
    display: flex;
    flex-direction: row;

    .min-max {
      flex-grow: 1;
    }

    .value {
      margin: 0 5px;
      flex-grow: 2;
    }
  }
}
