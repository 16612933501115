.support-sub-header {
  .sub-header .sub-header__column-left {
    width: inherit;
  }

  .sub-header .sub-header__column-right {
    width: inherit;
    margin-top: 14px;

    select {
      margin: 0;
    }
  }
}

.support-pane {
  margin: 20px;

  select, input {
    margin: 0;
  }
}