#customer-loans-cash-payment-div {

  .content {
    padding: 0 20px 0 20px;
  }

  .well {
    margin-top: 20px;
  }

  .bold {
    font-weight: bold;
  }

  .buttons {
    float: right;
  }
}