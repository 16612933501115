lookup-customer-modal {
  .modal {
    width: 750px;
  }

  .modal-body {
    max-height: 40rem;
  }

  .search-container {
    display: flex;
    margin : 0 -5px 10px -5px;

    & > * {
      margin: 0 5px !important;
    }
  }

  .table {
    td {
      vertical-align: middle;
    }

    .active {
      background: #e8e8e8;
    }
  }
}