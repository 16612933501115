.group-loans-create {
  .content {
    margin: 0 20px;
    overflow: hidden;
  }

  .group-loans-create-slide-up.ng-leave.ng-leave-active {
    transform: translateY(-100%);
  }

  .group-loans-create-slide-up.ng-enter {
    opacity: 0;
  }

  .group-loans-create-slide-up.ng-leave,
  .group-loans-create-slide-up.ng-enter {
    transition: all 0.3s ease-in-out;
  }
}