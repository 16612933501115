#dashboard_lookup_customer_div {
  text-align: center;

  #dlc_search_bar {
    background: white;
    padding: 20px 0 20px 0;
    border-bottom: solid 1px lightgray;
  }

  #pwn_corporate {
    width: unset;
  }

  form {
    display: inline-block;
    margin-bottom: 0px;
  }

  label {
    text-align: center;
    margin-left: 10px;
    color: dimgray;
  }

  input {
    width: 130px;
  }

  .extended {
    width: 180px;
  }

  .search-button {
    margin-left: 10px;
  }

  #dlc_spinner {
    margin-top: 100px;
    opacity: 0;
  }

  #dlc_results {
    padding: 20px;

    table {
      button {
        margin-left: 0;
      }
    }
  }

  #mask-checkbox {
    float: left;
    width: 17px;
    margin-left: 5px;
  }

  #mask-switch {
    display: inline-flex
  }

  @mask-color: #003399;

  #mask-product-input {
    color: @mask-color
  }

  #normal-icon {
    transform: rotate(90deg);
  }

  #mask-icon {
    transform: rotate(90deg);
    color: @mask-color
  }

}

#dashboard_lookup_customer_div.group-lookup {
  ul.members {
    .key {
      font-weight: bold;
    }
  }

  .inline-panel-table-cell {

    .member-details {

      display: flex;
      padding: 10px;

      .column {

        &:nth-child(1) {
          padding-right: 20px;
          border-right: solid 1px lightgray;
        }

        &:nth-child(2) {
          padding-left: 20px;
        }

        .header {
          font-weight: bold;
        }
      }
    }

    button {
      margin-top: 10px;
    }
  }
}