@separator-color: rgba(153, 153, 153, 0.35);
@header-background-color: rgba(255, 255, 255, 0.6);

gl-header .header-container {
  align-items: stretch;
  background: @header-background-color;
  border-bottom: 1px solid lightgray;
  display: flex;

  .header-item {
    align-items: center;
    display: flex;

    .header-text {
      display: inline-block;
      margin: 20px;
    }

    .header-button {
      margin-right: 10px;
    }

    &:last-child {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
    }
  }

  .bold {
    font-weight: bold;
  }

  > * + * {
    border-left: 1px solid @separator-color;
  }
}