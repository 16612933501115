#holiday-branches {
  .open-button {
    @padding: 25px;
    padding-left: @padding;
    padding-right: @padding;
  }
}

#holiday-list {

  table {
    th:last-child {
      text-align: right;
      padding-right: 35px;
    }
  }

  .holiday-list__ {
    &add-new-column {
      text-align: right;
    }

    &input {
      margin-bottom: 0;
      width: calc(100% - 5px);
    }
  }

  .save-button {
    margin-right: 5px;
  }
}