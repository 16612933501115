.markup-percentage-form {
  padding: 20px 0 0 40px;

  .spacing {
    margin: 5px 0 0 0;
  }
}

form {
  form-section-field {
    &.markup-label {
      font-weight: bold;
    }
  }
}