
.print-only {
  display: none;
}

@media print {

  .no-print {
    display: none;
  }

  .print-only {
    display: block;
  }

  .gritter-item-wrapper {
    display: none;
  }

  .sidenav, breadcrumb, sub-header, customer-profile-header, .report-filter {
    display: none;
  }

  body {
    padding: 0;
    zoom: 50%;
  }

  table {
    //page-break-inside: avoid;
  }

  button, button.btn {
    display: none;
  }

  select {
    // disable the arrow on select boxes
    -webkit-appearance: none;
  }
}