@import "~bootstrap/less/variables";

forex-document-type-details {
  .content {
    margin: 0px 15px;
  }

  .form-section-document {
    .form-section__fields {
      width: 500px;
      .form-section-field{
        .form-section-field-title{
          text-align: left;
        }
      }
    }
  }

  div.form-section {
    .form-section__title {
      margin-bottom: 10px;
    }
  }
}

forex-document-type-list {
  .edit-button {
    min-width: 100px;
  }

  .alert-info {
    margin-top: 15px;
  }
}