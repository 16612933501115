gl-transaction-list .header-container {
  .buttons {
    display: flex;
  }
}

gl-transaction-list .transaction-table-container {
  padding: 20px;

  .status {
    display: inline-block;
    font-weight: bold;
    padding-left: 15px;
  }

  .filters {
    display: flex;
    justify-content: flex-end;
    margin: -10px 0;

    > * {
      margin: 10px;
    }
  }


  .transaction-list-table {
    margin-bottom: 0;

    > th, > td {
      padding-left: 0;
      vertical-align: middle;
    }

    > td:first-child {
      padding-left: 8px;
    }

    tbody {
      background-color: white;
    }

    .transaction-item {
      height: 50px; // to prevent table from scrolling when changing pages
      &:hover {
        color: #555;
        text-decoration: none;
        background-color: #f8f8fa;
      }

      &.active-transaction {
        background-color: #f2f2f2;
      }

      td.details {
        padding: 0;
      }

      td[status=REJECTED] {
        color: red;
      }

      td[status=PENDING] {
        color: orange;
      }

      td[status=PROCESSED] {
        color: green;
      }

      td[transaction-type=MANUAL] {
        color: orange;
      }

      td[transaction-type=OPERATION] {
        color: green;
      }

      td[transaction-type=LEDGER_ACTION] {
        color: green;
      }

      td.posting-date {
        text-align: left;
      }
    }
  }
}
