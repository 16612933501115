@import '~Styles/variables';

areas {
  .tree {
    background: white;
  }

  button {
    width: 100px;
  }

  ul {
    list-style: none;

    &.root {
      margin: 0;
    }

    .list-row {
      height: 20px;
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.area {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .area:hover {
      background-color: @hoverItemBackgroundColor;
    }
  }

  .unassigned {
    background: white;
    ul {
      list-style-type: circle;
      li {
        padding: 8px;
      }
    }
  }
}