#edit-loan-amortization-div {

  padding-top: 20px;

  h3 {
    padding-top: 0;
  }

  input[type="number"] {
    width: 65px;
  }

  input[type="date"] {
    width: 120px;
  }
  select {
    width: 100px;
  }
}