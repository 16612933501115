#internet-check-div {
  z-index: 99999;
  //  position: fixed;
  text-align: center;
  width: 100%;

  .alert {
    margin: 0;
  }

  a {
    color: #b94a48;
    cursor: pointer;
    font-weight: bold;
  }
}
