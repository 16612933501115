.app-version-update {
  z-index: 99999;
  text-align: center;
  padding: 8px 35px 8px 14px;
  background-color: #5bc0de;
  color: #fff;

  .alert {
    margin: 0;
  }

  a {
    color: #b94a48;
    cursor: pointer;
    font-weight: bold;
  }
}