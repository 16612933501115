@import "~bootstrap/less/variables";

stock-items-sell-summary {
  .content {
    margin: 20px;

    table {
      .select-customer-container {
        input {
          margin-bottom: 0;
        }
      }

      .d-inline-block {
        &__field {
          display: inline-block;
          vertical-align: middle;
        }

        &__info {
          margin-left: 10px;
          color: @infoText;
        }
      }
    }
  }
}