#item-sale-receipt {

  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .iframe-preview {
    flex: 1 1 auto;
  }

  .iframe-invisible {
    height: 0;
    width: 0;
  }

  button.btn {
    width: 100px;
    margin-left: 5px;
  }
}