custom-fee {

  label.fee-as-board {
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-top: 5px;

    input {
      vertical-align: bottom;
      position: relative;
      margin: 0;
    }
  }

  select {
    margin: 0;
  }
}
