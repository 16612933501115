report-details {

  .filters {
    display: flex;
    align-items: end;
    justify-content: flex-end;
    padding-top: 5px;
    margin: 0 0 0 10px;

    .short-select {
      width: 110px;
    }

    > * {
      margin: 5px;
    }
  }

  form {
    margin-bottom: 0;
  }

  .form-section .form-section__title {
    height: auto;
    padding: 0;
  }

}
