#customer-term-deposits-create-list-div {
  .content {
    padding: 20px;
  }
}


// DETAILS
#customer-term-deposits-create-details-div {
  .content {
    padding: 20px;

    .entry-form {
      display: flex;
      flex-wrap: wrap;
    }

    .product-info-section {
      display: flex;
      margin: 20px 0;

      .product-attribute {
        flex-grow: 1;

        .key {
          font-weight: bold;
        }
      }
    }

    table {

      select, input {
        margin-bottom: 0;
      }

      td.labels {
        width: 280px;
      }

      th.numbers {
        width: 10px;
      }

      td.values {
        display: flex;
      }

      .row-info-section {
        margin-left: 5px;

        input {
          width: 80px;
        }

        .add-on {
          width: 30px;
        }

        label.row-info-checkbox {
          display: block;
          padding-left: 15px;
          margin-top: 5px;

          input[type=checkbox] {
            width: 13px;
            /* height: 13px; */
            padding: 0;
            margin: 0;
            vertical-align: bottom;
            position: relative;
            top: -4px;
          }
        }
      }

      input.value {
        margin: 0 10px;
      }

      .no-prefix-input {
        margin-left: 182px;

        .selectize-control {
          width: 220px;
        }

        .multiselect {
          width: 220px;
        }
      }
    }

    .new-term-deposit-check-table {
      td:nth-child(3) {
        width: 44%;
      }
    }
  }

  .buttons {
    float: right;
    margin-right: 40px;
  }
}
