amortization-payment {

  .input-table {
    margin-top: 10px;
  }

  table.table {
    box-sizing: border-box;
    max-width: 1240px;

    th {
      border: none;

      &.custom-payment-cell {
        display: flex;
        padding: 0;
        justify-content: flex-end;

        input {
          margin-right: 5px;
        }
      }

    }

    tbody {
      border: none;
    }

    .initial-row > * {
      opacity: 0.7;
    }

    .paid-row {

    }

    .paid {
      background-color: rgba(0, 255, 0, 0.26);
    }

    .partially-paid {
      background-color: rgba(255, 255, 0, 0.26);
    }

    .penalties-column {
      padding-right: 10px;
    }

    .status-col {
      text-align: center;
      width: 80px;
    }

    .paid-column {
      border-left: solid 1px lightgray;
      padding-left: 10px;
    }

    td.transparent, tr:hover td.transparent {
      background: transparent;
    }

    td.custom-payment-amount-input {
      display: flex;
      align-items: center;

      .max {
        margin-left: 10px;
        color: darkgray;
      }
    }

    td.checkbox-column {
      border: none;
      text-align: center;
      margin-top: 5px;
      display: flex;

      .hint {
        color: gray;
        width: 0;
        overflow: visible;
        white-space: nowrap
      }

      .hint:before {
        content: "\00a0\00a0";  // adds "margin" without table redraw
      }
    }

    input {
      margin-bottom: 0;
      max-width: 100px;
    }

    td {
      padding: 8px 4px;
    }

  }
}

@media print {
  amortization-payment {

    /* Allow table to stretch on page. Increase font size to be more visible on A4 */
    table.table {
      max-width: none;
      max-height: none;
      font-size: 24px;

      td.checkbox-column {
        display: none;
      }
    }

    .no-print {
      display: none;
    }

    /* Remove border from all inputs when printing */
    textarea, select, input[type] {
      border: none;
      font-size: 24px;
  }

    /* Hide styling of appended fields */
    span.add-on.glue-appended {
      border: none;
      background: transparent;
      font-size: 24px;
    }
  }
}