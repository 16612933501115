.gl-mappings {
  padding: 20px;

  .gl-mappings__title {
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid lightgray;
  }

  .gl-mappings-copy-container {
    float: right;
    .input-prepend {
      margin: 0 5px 0 0;
      label {
        margin: 0;
      }
    }
  }

  .gl-mappings__table {

    .no-column-header {
      width: 15px;
    }

    input[type='checkbox'] {
      width: 16px;
      height: 16px;
    }

    .centered-cell {
      text-align: center;
      vertical-align: middle;
    }

    .col-name {
      width: 30%
    }

    .col-delegate {
      width: 70px;
    }

    .col-account {
      width: 350px;
    }

    .col-delegate-tag {
      width: 350px
    }

    .disabled-select {
      width: 350px;
      margin: 0;
    }

    .selectize-control {
      max-width: 350px;
    }

    .selectize-dropdown {
      width: 350px !important;
    }

    .selectize-input {
      padding-top: 4px;
      padding-bottom: 4px;
      display: flex; // make the cursor appear right to the text instead of below when clicking on select.

      .account-options {
        height: 35px;
        width: 310px;
        overflow: hidden;
      }
    }
  }

  .missing-tags-message {
    padding: 15px 10px;
  }
}
