.information-bar {
  z-index: 99999;
  text-align: center;
  padding: 8px 35px 8px 14px;
  background-color: #5bc0de;
  color: #fff;

  h4 {
    margin: 0;
  }
}