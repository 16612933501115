check-list {
  .deposit-table-header {
    display: flex;
    justify-content: space-between;

    input {
      margin: 0;
    }
  }

  table.table td {
    &.right {
      text-align: right;
    }

    &.text-center {
      text-align: center;
    }
  }

  div[ng-table-pagination] {
    text-align: right;
  }
}