gl-account-list {
  .table {
    th, td {
      padding-left: 0;
      vertical-align: middle;
    }

    td:first-child {
      padding-left: 8px;
    }
  }

  .tbody {
    background-color: #ffffff;
  }

  .open-link-container {
    text-align: right;
  }

  .open-link {
    text-transform: uppercase;
    min-width: 60px;
  }
}