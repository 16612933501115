help {
  .help-icon {
    font-size: 1.4em;

    &:hover {
      @import '~bootstrap/less/variables';
      transition: color 0.2s ease;
      color: @blue;
    }
  }
}