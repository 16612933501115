.ent {
  @import '~CommonStyles/mixins';
  margin: 0;
  margin-top: 10px;
  padding: 0;

  .ng-invalid > & {
    .invalid-state();
  }

  &__input {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 -5px;

    & > * {
      margin: 0 5px;
    }

    & > input {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
  }

  &__tree {
    &-search {
      align-items: center;
      display: flex;
      padding: 10px 0;
      padding-left: 5px;
      margin: 0 -5px;

      & > * {
        margin: 0 5px;
      }

      & > input {
        margin: 0 !important;
      }
    }

    &-element {
      a {
        color: unset;
        text-decoration: none !important;
      }
    }
  }
}