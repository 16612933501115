#customer-deposit-accounts-memo-div {

  .content {
    margin: 0 20px;
    max-width: 900px;

    table {
      margin-top: 20px;

      tbody {
        background-color: transparent;

        td {
          background-color: white;
        }

        input {
          margin-bottom: 0;
          width: 150px;
        }

        select, .selectize-input {
          width: 410px;
          margin: 0;
        }

        tr.remarks {

          td {
            background-color: transparent;
            padding-top: 20px;
          }

          span.remarks {
            font-weight: bold;
          }

          span.remarks-total {
            float: right;
          }

          input.remarks {
            width: 290px;
          }
        }


      }

    }

    .buttons {
      float: right;
      margin-top: 20px;
    }

  }

}