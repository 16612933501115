.cash-amount-picker {

  max-width: 900px;

  .cash-amount-picker-list {
    overflow: hidden;
    margin: 0;

    li {
      list-style: none;
      float: left;
      margin-right: 20px;
      margin-bottom: 22px;
    }

    button {
      width: 200px;
      padding: 30px;
    }

    .li-other {
      margin-bottom: 0;
    }

    // terrible hackery!
    input.other-input {
      position: absolute;
      width: 190px;
      text-align: center;
      color: white;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      margin-top: -54px;
    }

    input.other-input::-webkit-outer-spin-button,
    input.other-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    .li-other .selected-other {
      font-size: 0;
    }
  }
}