@import '~bootstrap/less/variables';

custom-report {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .cr {
    &-container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &-nav {
      align-items: center;
      display: flex;
      padding: 10px 20px;

      &-item {
        align-items: center;
        display: flex;
        flex: 1 1 100%;

        > *:not(:last-child) {
          margin-right: 15px;
        }

        &:last-child {
          justify-content: flex-end;
        }

        input {
          margin-bottom: 0;
        }
      }
    }

    &-top {
      display: flex;
      height: 550px;

      &-editor {
        display: flex;
        flex-basis: 60%;
        flex-direction: column;
        max-width: 60%;
        padding: 0 20px 20px;
      }

      &-sidenav {
        border-left: 1px solid @white;
        box-sizing: border-box;
        flex-basis: 40%;

        .tab-content {
          max-height: calc(100% - 40px);
          overflow: auto;
        }
      }

      sql-editor {
        height: calc(100% - 2rem);
      }
    }

    &-bottom {
      border-top: 1px solid @white;
      flex: 1;
      overflow: auto;

      &-output {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      // Custom report-view styling
      report-view, .reports {
        display: block;
        height: 100%;
        position: relative;

        table {
          margin-bottom: 0;
        }

        .centered-gray-information-message {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
