report-filters {
  form {
    display: flex;
    flex-wrap: wrap;
    margin: unset;
    align-items: flex-start;


    .main {
      display: inline-flex;
      align-items: flex-start;

      .filter-element {
        display: inline-flex;
        margin-left: 5px;

        .filter {
          margin: unset;
        }
      }
    }
  }
}