roles-config {

  .disabled {
    pointer-events: none;
    cursor: default;
  }

  roles-list {
    span {
      padding-left: 25px;
    }

    table {
      th:last-child {
        text-align: right;
        padding-right: 35px;
      }
    }
  }

  role-write-access {
    display: block;

    .btn-group.block {
      display: block;
    }

    .rule {
      display: grid;
      grid-template-columns: 1fr 2fr max-content max-content;
      grid-gap: 10px;
      background: white;
      padding: 0 5px;
      border-bottom: solid 1px lightgray;

      & > * {
        padding: 5px;
      }

      .system-name {
        color: darkgray;
      }
    }

    .when {
      display: flex;

      .predicates {
        display: flex;
      }
    }

    .flags {
      input[type=checkbox] {
        margin: 5px;
      }
    }

    // common for all predicates
    .predicate {
      background: #ededef;
      padding: 5px;
      margin: 5px;
      border: 1px solid lightgray;
      border-radius: 10px;

      .close {
        padding-left: 8px;
        align-self: center;
        font-size: 16px;

        &:after {
          content: 'X';
        }
      }
    }

    .btn-group {
      // add button
      height: fit-content;
      margin: 5px;
    }
  }

  role-read-access {
    .predicate {
      width: fit-content;
      background: #ededef;
      padding: 5px;
      margin: 5px;
      border: 1px solid lightgray;
      border-radius: 10px;
    }
  }

  amount-predicate discount-predicate {
    display: block;
    white-space: nowrap;

    .predicate {
      display: flex;
      align-items: center;
      width: fit-content;

      glue-container {
        margin-left: 10px;

        input {
          max-width: 100px;
        }
      }
    }
  }

}
