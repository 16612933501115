watchlist-details-view {
  .alert {
    margin: 15px;
  }

  form {
    margin: 0;
  }

  div.form-section {
    box-sizing: border-box;
    width: 500px;
    float: left;
    padding: 0 40px 0 20px;
  }

  .buttons {
    float: right;
    margin: 20px;
  }

  .watchlist-entry {

    &__table {
      box-sizing: border-box;
      margin: 20px;
      width: calc(100% - 40px);

      input {
        margin: 0;
      }

      td, th {
        input.watchlist-entry__name {
          text-transform: uppercase
        }

        &:last-child {
          width: 220px;
        }
      }
    }

    &__button {
      width: 80px;
    }
  }
}