pawn-tag-list {
  table {

    td,th {
      &:nth-child(3) {
        width: 40%;
      }
      &:last-child {
        text-align: right;
        padding-right: 35px;
      }
    }
  }

  .tag-list__ {
    &add-new-column {
      text-align: right;
    }

    &input {
      margin-bottom: 0;
      width: calc(100% - 5px);
    }
  }

  .save-button {
    margin-right: 5px;
  }
}
