.group-loans-create-form {
  @import '~bootstrap/less/variables';
  @import '~CommonStyles/mixins';
  @border-width: 3px;

  .accordion {
    margin-bottom: 0;
  }

  .accordion-group {
    background: white;
    border: none;
    border-top: 5px solid #dddddd;
    margin-bottom: 0;

    &--invalid-form .accordion-header {
      .invalid-state;
    }

    &--template-item .accordion-header {
      box-shadow: none;
      border-bottom: @border-width solid @blue;
      margin: 0;
      padding: 8px @border-width;
      padding-bottom: 5px;
    }
  }

  .accordion-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    margin: @border-width;
    cursor: pointer;

    & > * {
      margin: 0 10px;
      cursor: default;
    }

    &-label {
      color: rgb(51, 51, 51);
      cursor: pointer;
      width: 200px;
      padding: 10px 5px;
      text-decoration: none;
    }

    &-form {
      max-width: 70%;

      &-field {
        td {
          padding: 3px 0;
        }

        .labels {
          padding-right: 5px;
        }

        .values {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin: 0 -2px;
          cursor: pointer;

          & > * {
            margin: 5px 3px;
            cursor: default;
          }

          select {
            margin-bottom: 0;
          }

          input {
            margin-bottom: 0;
            min-width: 40px;
            width: auto;
          }
        }
      }
    }

    &-buttons {
      padding: 2px 7px;

      & > * {
        margin: 5px 3px;
      }
    }

    &:hover {
      background: rgb(248, 248, 248);
    }
  }

  .buttons .btn {
    width: 80px;
  }
}