@import '~bootstrap/less/variables';

general-health-check {
  .hc-item {
    &-list {
      h4 {
        margin: 15px 0;
      }
    }

    &-wrapper {
      &-header {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
      }

      &-body {
        padding: 0 10px 10px;
      }

      &-check {
        background-color: @white;
        border-bottom: 1px solid @grayLight;

        &:last-child {
          border-bottom: none;
        }

        &:nth-child(2n - 1) {
          background-color: @grayLighter;
        }
      }
    }
  }
}