report-view {
  @import '../../../../style/variables.less';
  
  table {
    table-layout: fixed; // ensures all tables across the report are of the same size which plays well with <thead> at the top on every print page

    &.table.table-style.report-description {
      display: none;
      width: initial;

      &.table-half {
        width: 50%;
        float: left;
      }
      
      tr td {
        width: initial;
        white-space: pre-wrap;
      }

      tr:first-child td {
        border-top: none;
      }
    }
  }

  table.table.table-style {

    //font-size: 16px;

    @media print {
      font-size: @fontPrintSize !important;
    }

    th {
      border-top: none;
      text-align: left;
    }

    td {
      border: 1px solid @reportBorderColor;
    }

    tr.report-summary td {
      text-align: left;
      border: none;
    }

    th.align-right, tr.report-summary td.align-right {
      text-align: right;
    }

    .report-separator td {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .content {
    padding: 0 20px 20px;
  }

  .cursor-default {
    cursor: default;
  }

  pre {
    font-family: 'Open Sans', 'Helvetica', sans-serif;
    background-color: transparent;
    display: block;
    padding: 0;
    margin: 0;
    line-height: 20px;
    word-break: normal;
    word-wrap: break-word;
    white-space: pre-wrap;
    border: none;
    text-align: left;

    &.align-right {
      text-align: right;
    }

    @media print {
      font-size: @fontPrintSize !important;
    }
  }

  .key-value {
    &-container {
      align-items: center;
      display: flex;
      padding: 8px 0;
    }

    &-label {
      cursor: default;
      font-weight: bold;
      margin: 0;

      &:after {
        content: ':';
        display: inline-block;
      }
    }

    &-value {
      margin: 0;
      padding-left: 10px;
    }
  }

  @media print {
    table.table.table-style.report-description {
      display: block;

      &.table-half {
        display: inline-table;
      }
    }

    .filters {
      display: none;
    }
  }

}
