.form__title,
.misc-transaction__title,
.misc-transaction__error-title {
  border-bottom: 1px solid lightgray;
  width: 65%;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  margin-bottom: 8px;
}
.form__content,
.misc-transaction__content {
  box-sizing: border-box;
  margin: 30px 20px 20px;
}
.misc-transaction__error-title {
  color: indianred;
}
.miscellaneous-transactions__table {
  box-sizing: border-box;
  margin: 20px;
  width: calc(100% - 40px);
}
.miscellaneous-transactions__table td:first-child,
.miscellaneous-transactions__table th:first-child {
  width: 30px;
}
.miscellaneous-transactions__table td:last-child,
.miscellaneous-transactions__table th:last-child {
  width: 80px;
}
.miscellaneous-transactions__open-button {
  width: 80px;
  text-transform: uppercase;
}
.misc-transaction-listing__table {
  box-sizing: border-box;
  width: 100%;
}
.misc-transaction-listing__table td:first-child,
.misc-transaction-listing__table th:first-child {
  box-sizing: border-box;
  width: 40px;
}
.misc-transaction-listing__table td:nth-child(2),
.misc-transaction-listing__table th:nth-child(2) {
  width: 50%;
}
.misc-transaction-listing__table td:last-child,
.misc-transaction-listing__table th:last-child {
  padding-right: 2%;
  text-align: right;
}
.misc-transaction-listing__table .centered {
  text-align: center;
}
.misc-transaction-listing__table .error {
  color: indianred;
}
.misc-transaction__table {
  box-sizing: border-box;
  width: 100%;
}
.misc-transaction__table td:first-child,
.misc-transaction__table th:first-child {
  box-sizing: border-box;
  width: 40px;
}
.misc-transaction__table td:nth-child(2),
.misc-transaction__table th:nth-child(2) {
  width: 50%;
}
.misc-transaction__table td:last-child,
.misc-transaction__table th:last-child {
  width: calc(50% - 40px);
  padding-right: 12%;
}
.misc-transaction__table select,
.misc-transaction__table multiselect {
  margin: 0;
  width: 100%;
}
.misc-transaction__table input {
  width: 100%;
  box-sizing: border-box;
  height: 30px;
  margin: 0;
}
.misc-transaction__table .validation-container input {
  margin-bottom: 10px;
}
.misc-transaction__table .readonly-list {
  margin-bottom: 0;
}
.misc-transaction__table glue-container .transclude {
  width: 100%;
}
.misc-transaction__table glue-container input {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
}
.misc-transaction__table glue-container .add-on {
  box-sizing: border-box;
  height: 30px;
}
.misc-transaction__table .selectize-input {
  height: 30px;
  padding: 0 8px;
}
.misc-transaction__table .selectize-input .item {
  line-height: 28px;
}
.misc-transaction__table .selectize-control {
  height: 30px;
}
.misc-transaction__control-buttons {
  float: right;
}
.misc-transaction__control-buttons.check-control-buttons {
  margin-bottom: 15px;
}
.misc-transaction__accounts-table {
  table-layout: fixed;
}
.misc-transaction__accounts-table td:first-child,
.misc-transaction__accounts-table th:first-child {
  width: 30px;
}
.misc-transaction__accounts-table td:nth-child(2),
.misc-transaction__accounts-table th:nth-child(2) {
  width: 55%;
}
.misc-transaction__accounts-table td:last-child,
.misc-transaction__accounts-table th:last-child {
  width: 100px;
}
.misc-transaction__accounts-table td {
  background-color: #fff;
}
.misc-transaction__accounts-table input,
.misc-transaction__accounts-table select {
  width: 100%;
  box-sizing: border-box;
  height: 30px;
  margin: 0;
}
.misc-transaction__accounts-table glue-container .transclude {
  width: 100%;
}
.misc-transaction__accounts-table glue-container input {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
}
.misc-transaction__accounts-table glue-container .add-on {
  box-sizing: border-box;
  height: 30px;
}
.misc-transaction__accounts-table.table .add-account td {
  background-color: #ededef;
  padding-top: 30px;
}
.misc-transaction__accounts-table .add-account__remarks-input {
  width: calc(100% - 100px);
  margin-left: 35px;
}
.misc-transaction__accounts-table .add-account__remarks-total {
  float: right;
  line-height: 30px;
}
.misc-transaction__accounts-table .account-options {
  width: 98%;
}
.misc-transaction__accounts-table .selectize-control.single {
  max-width: none;
}
.misc-transaction__accounts-table .selectize-input {
  height: 30px;
  padding: 0 8px !important;
}
.misc-transaction__accounts-table .selectize-input .item {
  line-height: 28px;
}
.misc-transaction__accounts-table .selectize-control {
  height: 30px;
}
.multiselect-wrapper .multiselect {
  position: relative;
  margin: 0px;
}
