customer-forex-transaction-history {

  .horizontal {
    display: flex;
    padding: 5px;
  }

  .status {
    &-REVERTED, &-REJECTED, &-ERROR {
      color: red;
    }

    &-PROCESSED {
      color: green;
    }
  }
}
