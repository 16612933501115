.denomination {
  padding: 10px 20px;
  background-color: #ffffff;
  margin-bottom: 20px;

  .disabled-text {
    color: darkgray;
  }

  .denomination-footer {
    margin-top: 10px;

    .error {
      color: red;
    }

    p, label {
      float: left;
    }

    input {
      float: left;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .grid-separator {
    float: left;
    width: 14%;
  }

  .denomination-grid-wrap {
    width: 43%;
    float: left;

    .denomination-grid-na {
      margin: 100px 40px auto auto;
      text-align: center;
    }

    .denomination-grid-title {
      width: 100%;
      text-align: center;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .denomination-grid {
      width: 100%;
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: center;

      .column-wrapper {
        flex-grow: 1;
        flex-basis: auto;
        padding: 1px;

        table {

          td {
            padding: 4px;

            &:first-child {
              box-sizing: border-box;
              width: 60px;
              text-align: right;
              padding-right: 8px;
            }
            &:last-child {
              width: 50%;
            }
          }

          input {
            width: 100%;
            box-sizing: border-box;
            height: 30px;
            margin: 0;
          }
        }
      }
    }
  }
}
