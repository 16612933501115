gl-transaction-details {
  .transaction-details-table {
    border: 0;

    tbody tr {
      @import '~bootstrap/less/variables';
      border: 1px @tableBorder solid;
    }

    th:first-child {
      padding-left: 0;
    }

    td.credit-amount {
      color: green;
    }

    td.debit-amount {
      color: red;
    }

    td.account-code {
      display: flex;
      justify-content: space-between;
    }

    td {
      border: 0;
    }
  }

  .transaction-detail-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
  }
}