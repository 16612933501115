group-officers {
  #no_th {
    width: 5%;
  }

  #action_th{
    width: 15%;
  }

  #name_th{
    width: 40%;
  }

  #role_th{
    width: 40%;
  }

}