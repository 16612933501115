@import '~bootstrap/less/variables';
@import '../../../../../style/variables.less';

close-counters-health-check {
  .hc-item-cc {
    &-header, &-row {
      background-color: @white;
      border-bottom: 1px solid @grayLighter;
      
      &-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;

        > div {
          flex: 1px;
          
          &:first-child {
            max-width: 35px;
          }
        }
      }

      &-action:hover {
        text-decoration: underline;
        color: @activeItemFontColor;
        cursor: pointer;
      }

      &-table {
        border-top: 1px solid @grayLighter;
        padding: 10px;
        background: @grayLighter;
      }
    }
      
    &-header {
      background-color: @grayLighter;
    }
  }
}