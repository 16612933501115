@import '~codemirror/lib/codemirror.css';
@import '~codemirror/addon/hint/show-hint.css';
sql-editor {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
sql-editor > textarea {
  display: none;
}
sql-editor .CodeMirror {
  border: 1px solid white;
  flex: 1;
  height: 100%;
}
