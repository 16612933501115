modal-info-component {
  .info-label {
    width: 50%;
  }

  .info-value {
    width: 50%;
    padding-left: 10px;
  }
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
}

