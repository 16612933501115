shift-list {
  table {
    td,th {
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 30%;
      }
      &:last-child {
        text-align: right;
        padding-right: 35px;
      }
    }
  }

  .shift-list__ {
    &add-new-column {
      text-align: right;
    }

    &input {
      margin-top: 10px;
      width: calc(100% - 5px);
    }

    &days-of-week {
      margin-bottom: 0;
      width: calc(100% - 5px);
    }
  }
}
