.deposit-product-information {
  margin-top: 20px;
  display: flex;

  .key {
    font-weight: bold;
    margin-right: 5px;
  }

  .override {
    display: inline-flex;
    white-space: nowrap;

    input {
      margin: 4px;
    }
  }

  .value {
    margin-right: 50px;

    &.php-positive {
      color: green;
    }
    &.php-negative {
      color: red;
    }

  }

}