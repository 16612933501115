@arrow-width: 6px;
@separator-color: rgba(153, 153, 153, 0.35);
@breadcrumb-background-color: rgb(255, 255, 255);


breadcrumb {
  align-items: stretch;
  background: @breadcrumb-background-color;
  border-bottom: 1px solid @separator-color;
  display: flex;

  .breadcrumb-icon {
    align-items: center;
    border-right: 1px solid @separator-color;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 60px;

    > * {
      font-size: 8px;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }

  .breadcrumb {
    align-items: center;
    background: none;
    display: flex;
    margin-bottom: 0;
    padding-left: 30px;
    flex-grow: 1;

    .breadcrumb-item {
      color: rgb(0, 0, 0);
      font-weight: 500;
      text-transform: uppercase;

      .separator {
        padding: 5px;
      }
    }
  }

  .popover {
    max-width: 400px;

    .arrow {
      display: none;
    }
  }

  .popover.left {
    // initially the middle of popover is aligned with the middle of info text -
    // we need to move it 50% downwards, then the top of popover is aligned with the middle of info text
    // so we additionally need to move popover by the half of font size,
    // the width of an arrow, plus 1px for better visual appearance

    // x is simpler as we only need to get rid of the default popover right arrow
    // and add 4x letters' width to match popover right border with info text right border
    transform: translateY(calc(~"50% + 0.5em + @{arrow-width} + 1px")) translateX(calc(~"@{arrow-width} + 4ch")) ;
  }
}

.breadcrumb__info {
  display: flex;

  .error {
    color: red;
  }
}

.breadcrumb__info-section {
  box-sizing: border-box;
  display: flex;
  font-weight: bold;
  padding: 10px;
}

.breadcrumb__logout-section {
  box-sizing: border-box;
  cursor: pointer;
  width: 60px;
  height: 100%;
  text-align: center;
  border-left: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb__user-image {
  background-image: url('./../../../app/components/common/breadcrumb/profile-fallback.png');
  filter: opacity(0.3);
}

.breadcrumb__info-image {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}

.breadcrumb__info {
  margin: 0;
  line-height: 20px;
}

.breadcrumb__info-footer {


}

.breadcrumb__info-details {
  line-height: 20px;
  color: #999;
}


.breadcrumb__info-details--more {
  position: relative;

  .arrow {
    display: none;
  }

  &:focus .arrow {
    border: @arrow-width transparent solid;
    border-top-color: #0088cc;;
    box-sizing: border-box;
    content: " ";
    display: block;
    height: @arrow-width;
    left: 50%;
    position: absolute;
    margin-top: -2px;
    transform: translateX(-50%);
    width: @arrow-width;
  }
}

.breadcumb__info-details--more-container {
  position: relative;
}

.breadcrumb__more-popup-container {
  font-weight: normal;

  .breadcrumb__more-popup {
    &-item {
      align-items: center;
      display: flex;
      padding: 5px 0;
    }

    &-icon {
      height: 20px;
      margin-right: 5px;
      width: 20px;
    }

    &-label {
      padding-right: 5px;
      flex-shrink: 0;
    }

    &-value {
      flex-shrink: 0;
    }
  }

}

a.breadcrumb__info-details {
  cursor: pointer;
  text-decoration: underline;

  &:focus {
    color: #0088cc;
    outline: none;
  }
}

.breadcrumb__logout {
  font-size: 25px;
  line-height: 60px;
}

.breadcrumb__info-container {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.breadcrumb__branch-select {
  width: 220px;
}