.term-deposit-menu {
	@light-gray: #fafafa;
	@border-color: lightgray;

	font-size: 0;
	margin: 0;
	border-bottom: 1px solid @border-color;
	background: @light-gray;

	&__item {
		@term-deposit-menu-height: 40px;
		@green: #2bc88e;

		box-sizing: border-box;
		font-size: 14px;
		text-transform: uppercase;
		list-style: none;
		display: inline-block;
		width: 25%;
		height: @term-deposit-menu-height;
		line-height: @term-deposit-menu-height;
		text-align: center;
		cursor: pointer;

		&.inactive {
			color: @border-color;
			cursor: not-allowed;
		}

		&.active {
			border-bottom: 5px solid @green;
		}

		i {
			color: red;
		}
	}
}

.term-deposit__control-buttons {
  padding-right: 40px;
  text-align: right;
}
