#loan-check-release {

  .content {
    padding: 0 20px 0 20px;

    table {
      margin-top: 10px;

      input {
        margin: 0;
      }
    }

    .remarks {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      max-width: 450px;

      .caption {
        font-weight:  bold;
        margin-right: 20px;
      }

      input {
        width: 100%;
        margin: 0;
      }
    }

    .control-buttons {
      float: right;
    }
  }

}