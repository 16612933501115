.product-operation {

  input {
    margin-bottom: 0;
  }

  table.table {
    margin-bottom: 0;

    tr:first-child td {
      border-top: 0;
    }
  }

}