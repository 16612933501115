stock-item-create-layaway-view {
  .content {
    padding: 20px;

    .select-customer-container {
      input {
        margin-bottom: 0;
      }
    }
  }
}
