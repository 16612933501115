.mini-menu {
  ul.nav {
    margin: 0;
    border: none;

    li > a, li > a:hover {
      color: black;
      background: #f8f8fa;
      border: none;
      border-radius: inherit;
      //min-width: 124px;
      margin: 0 2px 0 0;
      text-align: center;
      cursor: default;
    }

    li.active > a {
      background: white;
      font-weight: bold;
    }
  }

  .tab-content {
    background: white;
  }
}