print-disclosure-statement {

  .content {
    border: 0;
    padding: 0 5px;
    & > div {
      margin-top: 30px;
    }
  }

  .flex-container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .others {
    padding-bottom: 20px;
  }

  .signature {
    flex-basis: 40%;
  }

  ul {
      margin-top: 10px;
      margin-left: 100px;
      margin-right: 130px;
      width: 100%;
      padding:0;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid black;
    margin: 3px 0;
    padding: 0;
    background: transparent;
  }

  @media print {

    .content {
      padding: 0 !important;
      margin-top: 0 !important;
      border: none !important;
    }

    hr {
      border-top: 1.5px solid black;
    }
  }

}