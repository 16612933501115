@import '~Styles/variables';

.dashboard {
  padding: 20px;

  &__tiles {
    display: flex;
  }

  &__tile {
    width: 33%;
    background-color: white;
    border: 1px solid @reportBorderColor;
    padding: 20px 20px 30px 20px;
    font-size: 14px;
    line-height: 36px;
    display: flex;
    flex: auto;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;

    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: 20px;
    }
  }

  &__tile-label {
    display: block;
    font-size: 22px;
    align-self: flex-start;
  }

  &__tile-value {
    display: block;
    font-size: 36px;
    align-content: center;
    align-items: center;
  }

  table > thead > tr > th {
    width: 33%;
  }
}
