prints-details {

  #prints-details__pdfWrapper {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 56%;
    overflow: hidden;
  }

  #prints-details__pdfWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .prints-details__iframe-hidden {
    opacity: 0;
  }
  .prints-details__title {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 8px;
  }

  .print-details__file-upload {
    float: left;
  }

  .print-details__control-input {
    width: 60px;
  }

  .print-details__control-number {
    width: 20px;
  }

  .print-details__buttons {
    margin: 0 -2.5px;

    & > button {
      margin: 0 2.5px;
    }
  }
}
