@import '../../../../style/variables.less';
@import '~bootstrap/less/variables';

health-check-list {
  padding-top: 15px;
  padding-bottom: 15px;

  .hc-item {
    margin-top: 40px;

    &.regular {
      padding: 0;

      &.fail, &.ok {
        border-width: 0;

        .hc-item-wrapper {
          border-width: 0;
        }
      }

      h4 {
        display: none;
      }
    }

    h4 span {
      float: right;
    }

    &-wrapper {
      background-color: @white;
      padding: 15px;

      .status {
        &.fail {
          color: @invalidValidationColor;
        }

        &.ok {
          color: @activeItemFontColor;
        }
      }
    }

    &-table {
      width: 100%;
      table-layout: fixed;
      text-align: left;
      border-collapse: collapse;
      background-color: @white;

      tr {
        &:not(:last-child) {
          border-bottom: 1px solid @grayLighter;
        }

        &:hover {
          background-color: @grayLighterHover;
        }
      }

      td, th {
        padding: 10px;
      }
    }

    
    &.ok {
      .hc-item-wrapper {
        border: 1px solid @grayLight
      }
    }

    &.fail {
      .hc-item-wrapper {
        border: 1px solid @grayLight
      }
    }
  }
}
