.loader-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 99999;
  right: 0px;
}

.loader-div {
  margin-top: 250px;
  padding: 60px;
  border: solid 1px darkgray;
  background: white;
  display: inline-block;
}

.loader-bg {
  background: black;
  opacity: 0.2;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99991;
}

/* position the text relative to the spinner */
#loader_text {
  float: right;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-left: 50px;
}