security-machine-specific {
  .security-machine-specific {
    &__control-buttons {
      display: flex;
      justify-content: flex-end;
      margin: 0 -2px;

      & > * {
        margin: 0 2px;
      }
    }
  }

}