users-change-branch {
  .content {
    background-color: white;
    vertical-align: middle;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;

    .select-branch {
      padding-top: 10px;
      margin: unset;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
    padding: 10px 10px 10px;
  }
}