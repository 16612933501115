history-miscellaneous-transactions {
  .modal .revert-remarks {
    &-container {
      display: flex;
      align-items: center;
    }

    &-input {
      margin: 0;
      margin-left: 10px;
    }
  }
}