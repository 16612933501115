.ledger-units-table {
  table-layout: fixed;

  td, th {
    &:first-child {
      width: 30px;
    }

    &:nth-child(2) {
      width: 55%;
    }

    &:last-child {
      width: 100px;
    }
  }

  td {
    background-color: #fff;
  }

  input, select {
    width: 100%;
    box-sizing: border-box;
    height: 30px;
    margin: 0;
  }

  glue-container {
    .transclude {
      width: 100%;
    }

    input {
      box-sizing: border-box;
      width: 100%;
      height: 30px;
    }

    .add-on {
      box-sizing: border-box;
      height: 30px;
    }
  }

  &.table .add-account {
    td {
      background-color: #ededef;
      padding-top: 30px;
    }
  }

  .account-options {
    width: 98%;
  }

  .selectize-control.single {
    max-width: none;
  }

  .selectize-input {
    height: 30px;
    padding: 0 8px !important;

    .item {
      line-height: 28px;
    }
  }

  .selectize-control {
    height: 30px;
  }

  .error {
    padding-top: 5px;
    color: red;
    float: right;
  }

  .error-cell {
    color: red;
  }
}

