#waive-div {

  .content {
    padding: 0 20px 0 20px;
    .pay-all-block {
      margin: 4px 2px 0 0;
      .pay-all-line {
        margin: 0px 2px 0 0;
      }
    }
  }

  .well {
    margin-top: 20px;
  }

  .bold {
    font-weight: bold;
  }

  .buttons {
    float: right;
  }
}