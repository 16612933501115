prints-per-product-group {
  .buttons {
    margin: 0 -2.5px;

    & > * {
      margin: 0 2.5px;
    }
  }
}

