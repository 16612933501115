gl-account-details {
  .table-container {
    padding: 20px;

    .table {
      table-layout: fixed;

      th {
        &.number {
          width: 35px;
        }
        &.name {
          width: 70%;
        }
        &.code {
          width: 15%;
        }
        &.balance {
          width: 15%;
        }
      }
    }

    .no-column {
      width: 50px;
    }
  }

  .account-row {
    .icon-container {
      opacity: 0;
      user-select: none;
    }

    .group-label {
      padding: 2px 6px;
    }

    .toggle {
      cursor: pointer;

      .icon-container {
        opacity: 1;
      }
    }

    &:not(.collapsed) .icon-expand {
      display: none;
    }

    &.collapsed .icon-collapse {
      display: none;
    }

    .buffer {
      display: inline-block;
    }
  }
}