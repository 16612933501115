#dashboard_new_customer_div {

  .header-item .header-text {
    .information {
      margin-right: 20px;
      border: none;
    }

    select {
      margin-bottom: 0;
    }
  }

}