#pawn-item-form {

  td, th {
    &:first-child {
      box-sizing: border-box;
      width: 20px;
    }
    &:nth-child(2) {
      width: 50%;
    }
    &:last-child {
      padding-right: 20px;
    }
  }

  textarea {
    width: 98%;
    margin: 0;
    resize: none;
  }

  .pawn-item-sub-listing {

    .inner-table-title {
      float: left;
      width: 50%;
      padding-top: 4px;
    }

    .inner-table {
      width: 100%;

      td, th {
        padding: 8px 0 8px 8px;
        border-top: 0;
        min-width: 100px;
        &:first-child {
          box-sizing: border-box;
          min-width: 40px;
          width: 40px;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:last-child {
          width: 100px;
          padding-right: 0;
        }
      }

      tfoot tr:first-child td {
        text-align: right;
      }
    }
  }
}

.metal-value {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;

  cash {
    width: 83%;

    div {
      width: 100%;
    }
  }

  button {
    width: 15%;
  }
}

.override-pawn-metal-rate {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  margin: 5px 0 0 0; 

  .karat-selection {
    display: inline-block;
    width: 50%;
  }

  .karat-price-label {
    display: inline-block;
    margin-left: 20px;
  }

  .karat-price-input {
    display: inline-block;
    width: 60%;
  }

  cash {
    line-height: 40px;
  }

  input[type=checkbox] {
    width: auto;
    display: inline;
  }

  .karat-override-checkbox {
    display: inline;
    margin: 0 0 0 15px;

    label {
      display: inline;
    }
  }
}