#customer-term-deposits-div {

}

#customer-deposit-accounts-cash-action-div {

  .content {
    padding: 0 20px;
    max-width: 100%;

    .cash-amount-picker {
      margin-top: 20px;
    }

    .buttons {
      float: right;
      margin-top: 40px;
      margin-right: 40px;
    }

    #recalculate-tax-btn {
      height: 30px;
      width: auto;
    }

  }

}