.local-override {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  outline: none;
}

.local-override__content {
  background: #2bc88e;
  color: #fff;
  text-align: center;
  position: relative;
  top: 35%;
  padding-top: 40px;
  padding-bottom: 40px;

  .pin-input-field {
    height: 60px;
    width: 60px;
    border: 3px solid #fff;
    text-align: center;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    font-size: 25px;
    color: #fff;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    &:focus {
      outline: none;
      border: 3px solid #fff;
      box-shadow: none;
    }
  }

  #approval-user-select-title {
    text-transform: uppercase;
    font-size: 23px;
    margin-bottom: 20px;
  }

  #approval-user-select {
    width: 360px;
    margin-bottom: 20px;
  }
}

.local-override__title {
  text-transform: uppercase;
  font-size: 23px;
  margin-bottom: 20px;
}

#local-override__submit {
  height: 44px;
  width: 360px;
  background-color: transparent;
  outline: none;
  border: 3px solid #fff;
  box-shadow: none;
  border-radius: 0;
  font-size: 26px;
  color: #fff;
  margin-top: 30px;
  text-transform: uppercase;
  transition: color .3s ease, background-color .3s ease;

  &:hover {
    background-color: #fff;
    color: #666;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
