#reportModal {
  position: absolute;
  left: 0;
  right: 0;
  width: 80%;
  height: 80%;
  margin: 0 auto;

  .modal-body {
    max-height: 80%;
  }

  @media print {
    background-color: white !important;
    height: auto;
    margin: 0;
    top: 0;
    width: 100%;

    .modal-header, .modal-footer {
      display: none;
    }
  }

  .centered-gray-information-message {
    position: inherit;
  }

}