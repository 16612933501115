term-deposit-section {

  .term-deposit-section__override-container {
    display: flex;
    justify-content: center;

    glue-container {
      flex-grow: 1;
    }

    label {
      margin-left: 5px;
    }
  }

  .term-deposit-section__input {
    box-sizing: border-box;
    width: 500px;
    float: left;
    padding: 0 40px 0 20px;
  }

}

// TODO: .term-deposit-section must be global since it is used by other componets, eg.
// customer-profile-*, administartion/users-details
.term-deposit-section form {
  margin: 0;
}

.term-deposit-section__column {
  box-sizing: border-box;
  width: 500px;
  float: left;
  padding: 0 40px 0 20px;
}
