group-members {
  .content {
    padding: 20px;
  }

  .member-list-row {
    vertical-align: middle;
    td:first-child {
      width: 25px;
    }
    td:last-child {
      width: 80px;
    }
  }

  .actions {
    display: flex;
    align-items: stretch;
    margin: 0 -5px;

    > * {
      margin: 5px;
      display: flex;
      align-items: center;
    }
  }

}
