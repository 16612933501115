#customer-loans-check-payment-div {

  .content {
    padding: 0 20px 0 20px;

    .buttons {
      float: right;
    }
  }

  @media print {
    .form-section__title{
      font-size: 24px;
      border-bottom: none;
    }
    .no-print{
      display: none;
    }
  }

}