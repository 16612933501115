@import '../variables.less';

.customer-header {
  background-color: #f8f8fa;
  border-bottom: 1px solid lightgray;

  display: flex;
  height: 60px;
  width: 100%;
  align-content: stretch;

  .profile-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;

    img {
      max-width: 60px;
      max-height: 60px;

      &:hover {
        opacity: 0.8;
      }
    }

    &.fake-picture {
      background: url('./../../app/components/common/breadcrumb/profile-fallback.png') no-repeat center;
      background-size: 50px 50px;
      width: 60px;
      height: 60px;
      position: relative;
      filter: opacity(0.3);

      &.image-exists {
        cursor: pointer;
        filter: opacity(0.3) drop-shadow(0 0 0 lime);
        transition: 0.2s ease-in-out;

        &:hover {
          filter: opacity(0.4) drop-shadow(0 0 0 lime);
        }
      }
    }
  }

  .section {
    display: flex;
    align-items: center;
    padding-left: 30px;
    border-left: solid 1px lightgray;
    flex-grow: 1;

    .title {
      font-weight: bold;
      margin-right: 5px;
    }

    .value {

    }
  }

  .transcluded {
    line-height: 60px;
    vertical-align: middle;
    margin-right: 20px;
  }
}

.customer-signature-popup {
  position: fixed;
  background: rgba(0,0,0,0.5);
  top: 0;
  left: 190px;
  width: ~"calc(100% - 190px)";
  height: 100%;
  z-index: 99999;
}

.customer-signature-popup__container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.customer-signature-popup__image_container {
  background: white;
}

.customer-signature-popup__remarks {
  text-align: center;
}

.customer-signature-popup__ {
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
  margin: auto;
  display: block;
  max-width: 70%;
}
