#customer-layaways-div {

  .content {

    .form-section__title {
      height: 25px;
      padding: 0;
    }

    .products-menu {
      .tab-content {
        overflow: inherit;
      }
    }
  }
}