//.multiselect-wrapper > div.form-section-field {
//  z-index: 1;
//  position: relative;
//}


multiselect {
  display: inline-flex;

  .multiselect {
    line-height: 20px;
    color: #555555;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    cursor: pointer;
    position: relative;
    width: 100%;

    &.inactive {
      background-color: inherit;
      cursor: not-allowed;
    }

    span {
      display: block;
      font-size: 14px;
      height: 29px;
      padding: 2px 9px;
      box-sizing: border-box;
      line-height: 24px;
      width: 100%;

      &:after {
        content: "\25BC";
        padding: 0;
        float: right;
      }

      &.invalid {
        border-color: transparent;
        box-shadow: 0 0 6px #DD0000;
      }
    }

    .multiselect__options {
      margin: 0;
      margin-top: 1px;
      position: absolute;
      max-height: 300px;
      min-width: 100%;
      overflow-y: auto;
      background-color: #ffffff;
      z-index: 10;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 3px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .multiselect__item {
      list-style: none;
      line-height: 27px;

      .multiselect__checkbox {
        height: inherit;
        width: 20px;
      }

      label {
        display: inline-block;
        margin-bottom: 0;
      }
    }

    .multiselect__item_all_option {
      &:extend(.multiselect__item);
      border-bottom: 1px solid gray;
    }

    .load-more {
      color: #555555;
      font-weight: bolder;
    }
  }

}