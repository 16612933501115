custom-fee-loan-board {

  .table th, .table td {
    text-align: center;
  }

  input, select {
    width: auto;
  }

  /*aligh glue-container's input in center of td*/
  div.input-append {
    justify-content: center;
  }

  p.title {
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid lightgray;
  }

  .form-section-field-title {
    width: auto;
  }

  div.alert {
    margin: 0;
  }

}
