prints-list {
  .active {
    color: green;
  }

  th:last-of-type {
    text-align: right;
  }

  .prints-list__actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin: 0 -2.5px;

    & > * {
      margin: 0 2.5px;
    }
  }
}

