customer-layaway-payments-report-view {
  .content {
    padding: 20px;

    .filters {
      display: flex;
      flex-wrap: wrap;
    }

    table {
      .tdcenter {
        text-align: center;
      }

      .actions-wrapper {
        display: flex;

        &__button {
          align-items: center;
        }
      }
    }
  }
}