group-loans-disbursment {

  .signatures {
    display: none;
    margin-top: 20px;

    table {
      width: 400px;

      th {
        text-align: left;
      }

      .underline{
        border-bottom: solid 1px black;
        margin-right: 40px;
        height: 40px;
        width: 300px;
      }
    }
  }

  .content {
    margin-left: 10px;
    margin-right: 20px;
  }

  .buttons {
    text-align: right;
  }

  @media print {

    #signatures-selection-table {
      display: none;
    }

    .signatures {
      display: inherit;
    }
  }

  .yellow-outline {
    box-shadow: 0 0 6px #e6e600;
    border-color: #e6e600;
  }
}