stocked-items-reprice-summary {
  .content {
    background-color: white;
    vertical-align: middle;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;

    .select-branch {
      padding-top: 10px;
      margin: unset;
    }

    .action-buttons {
      margin-left: auto;
      padding: 10px 10px 10px;
    }

    .stone-markup-field {
      width: 55%;
    }

    glue-container.stone-markup .transclude {
      display: contents;
    }

    tr {
      td.center-align {
        text-align: center;
      }
    }
  }
}