inline-panel {
  @import '../variables';

  background-color: @bodyColor;
  display: block;
  font-weight: normal;
  padding: 5px 0 5px 5px;

  button.btn.inline-panel-hide {
    background: #fff;
    border: 0;
    box-shadow: none;
    font-size: 24px;
    padding: 8px;
    position: absolute;
    right: 0;
    width: auto;
  }

  .inline-panel-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    position: relative;
  }

  .key-value {
    &-list {
      opacity: 1;
      overflow: hidden;
      transition: max-height 0.3s ease;
    }

    &-list-loading {
      opacity: 0;
      max-height: 0;
    }

    &-container {
      align-items: center;
      display: flex;
      padding: 8px 0;
    }

    &-label {
      cursor: default;
      font-weight: bold;
      margin: 0;

      &:after {
        content: ':';
        display: inline-block;
      }
    }

    &-value {
      margin: 0;
      padding-left: 10px;
    }
  }
}

table.inline-panel-table {
  position: relative;
}

th.inline-panel-table-header {
  padding: 0;
  margin: 0;
}

td.inline-panel-table-cell {
  padding: 0;
  position: absolute;
  right: 0;
  width: 30%;
  min-width: 400px;
}