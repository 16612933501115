div.form-section {

  div.form-section-field {

    select.term-interval {
      width: 50%;
    }

    glue-container.max-width {
      width: 100%;
    }
  }
}