amount-condition {
  display: block;
  white-space: nowrap;

  .condition {
    display: flex;
    align-items: center;
    width: fit-content;

    glue-container {
      margin-left: 10px;

      input {
        max-width: 100px;
      }
    }

    .inputs {
      display: flex;
      align-items: flex-end;
      margin: 9px 0;
      height: 30px;

      input {
        width: 150px;
      }
    }
  }
}