table.pawn-operation {

  td.input-label {
    vertical-align: middle;
  }

  td.input-cell {
    vertical-align: middle;
    padding-bottom: 0;
  }
}
