branch-details {

  .selectize-control.single {
    max-width: none;
    padding: 6px 0;
  }

  .selectize-input {
    height: 30px;
    padding: 0 8px !important;

    .item {
      line-height: 28px;
    }
  }
}
