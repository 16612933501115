#dashboard_action_list_div {

  .content {
    padding: 20px;

    .filters {
      display: flex;
      justify-content: flex-end;
      margin: -20px 0 0;

      > * {
        margin: 10px;
      }

      .status {
        font-weight: bold;
      }
    }

    .table-actions {
      .action-button {
        width: 120px;
      }
    }


    .action-tab-content {
      overflow: visible;
    }

    .action-detail-footer{
      display: flex;
      justify-content: flex-end;
      margin: 0 -5px;

      .action-button {
        margin: 5px;
      }
    }
  }
}

.dashboard_action_view_profile_div {

  .dashboard_action_view_profile_edit-button {
    margin-right: 10px;
  }

}