system-health-check {
  .right-header-panel {
    display: flex;
    float: right;
    margin-top: 15px;

    .switch {
      display: flex;
      align-items: center;
      margin-right: 10px;

      label {
        margin-bottom: 0;
        margin-left: 1px;
        user-select: none;
      }
    }
  }
}