
.customer-loans-create-details, #customer-loans-div-edit {
  .content {
    padding: 0 20px;

    .entry-form {
      display: flex;
      flex-wrap: wrap;
    }

    .product-info-section {
      display: flex;
      padding: 20px 0;

      .product-attribute {
        flex-grow: 1;

        .key {
          font-weight: bold;
        }
      }
    }

    table {
      margin-bottom: 0;

      select, input, textarea {
        margin-bottom: 0;
      }

      td.labels {
        width: 280px;
      }

      th.numbers {
        width: 10px;
      }

      td.values {
        display: flex;
        align-items: center;
      }

      .row-info-section {
        input {
          width: 80px;
        }

        .add-on {
          width: 30px;
        }
      }

      input.value {
        margin: 0 10px;
      }

      .no-prefix-input {
        margin-left: 182px;
      }

      .glued-input input {
        width: 165px;
      }

      .cycle {
        margin-left: 20px;

      }

      .btn-amortization {
        width: 173px;
        margin-left: 5px;
      }
    }
    .content {
      padding: 0 0;
    }
  }

  #loan-parameters {
  }

  #loan-co-maker {

    #co-makers-section > div.form-section {
      margin-top: 20px;
    }

    table {
      margin-top: 10px;

      tr:first-child td {
        border-top: none;
      }

      td.numbers {
        width: 20px;
      }

      td.labels {
        //width: 95%;
      }
    }

  }


  .override-charges {
    margin-top: 20px;
  }

  .buttons {
    float: right;
    margin: 20px 40px 20px 0;
  }
}
