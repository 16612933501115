#pre-terminate-div {
  .content {
    padding: 0 20px 0 20px;
  }

  .bold {
    font-weight: bold;
  }

  .buttons {
    float: right;
  }
}