pawn-auction-list {
  .pawn-types__ {
    &all {
      display: flex;
      justify-content: flex-start;
      padding: 10px;
    }

    &container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}