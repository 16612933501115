@import '../../../../style/variables.less';

tree-filter {
  border-radius: 0 4px 4px 0;
  display: inline-block;
  background: #EEF4FA;

  &.ng-invalid {
    box-shadow: 0 0 6px @invalidValidationColor;
  }
}
