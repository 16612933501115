#config_accounts_div {
  button {
    margin-left: 0;
  }

  .modal-inner {
    padding: 30px 50px 20px 50px;
  }

  #ca_table_accounts {
    padding: 0 20px;
  }

  #ca_btn_new_account {
    margin-left: 20px;
  }
}