@border-color: #ccc;

div.form-section {

  div.form-section-field {
    clear: both;
    display: flex;
    align-items: center;

    div.multiselect-wrapper {
      margin: 9px 0;
    }

    .horizontal {
      display: flex;
      .right-margin {
        margin-right: 3px;
      }
      .left-margin {
        margin-left: 3px;
      }
      .half-size {
        width: 50%;
      }
    }

    div.form-section-field-title {
      box-sizing: border-box;
      display: block;
      font-size: 14px;
      text-align: right;
      padding-right: 5px;
    }

    div.form-section-auto-width-title {
      width: auto;
    }

    div.form-section-fixed-width-title {
      width: 35%;
    }

    div.form-section-field-input {
      display: block;
      width: 65%;

      glue-container .transclude {
        width: 100%;
      }
    }

    // this moves some elements which have a too small height down so that they are centered.
    // TODO: generify this so that it works on all elements, not only the ones listed here

    .input-append .add-on {
      margin: 9px 0;
    }

    .input-prepend .add-on {
      margin: 9px 0;
    }

    input, select, .file-upload-container, account-select .selectize-control{
      box-sizing: border-box;
      height: 30px;
      width: 100%;
      margin: 9px 0;

      &.small-input {
        width: 49%;
      }

      &.pull-left {
        float: left;
      }

      &.pull-right {
        float: right;
      }
    }

    multiselect {
      width: 100%;
      margin: 9px 0;
    }

    account-select input {
      height: unset;
    }

    input[type="checkbox"] {
      width: auto;
    }

    input[type="file"] {
      background-color: initial;
    }

    textarea {
      resize: none;
      width: 100%;
      box-sizing: border-box;
    }
  }
  .form-section-field--info {
    .form-section-field {
      padding-top: 10px;

      div.form-section-field-title {
        top: 0;
        transform: translateY(0);
      }
    }
  }

  .form-section__title {
    box-sizing: border-box;
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 24px;
    padding-top: 23px;
    border-bottom: 1px solid @border-color;

    &.form-filters {
      display: flex;
      align-items: flex-start;

      .filters-wrapper {
        display: flex;
        align-items: flex-start;
        margin-left: 10px;
        text-transform: initial;
      }
    }
  }
}

.profile-body .remove-button.remove-button--right {
  margin-left: 35%;
  width: 65%;
}
