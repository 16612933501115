working-day-check {
  .working-day-warning {
    z-index: 99999;
    text-align: center;
    width: 100%;

    .alert {
      margin: 0;
    }

    a {
      color: #b94a48;
      cursor: pointer;
      font-weight: bold;
    }
  }

  .working-day-overlay {
    z-index: 99999 + 1;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(51, 51, 51, 0.7);
  }

  .message-container {
    width: 100%;
    min-height: 200px;
    display: table;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #2bc88e;
    text-align: center;
    color: #f1f1f1;
    padding-bottom: 30px;

    h4 {
      margin-top: 75px;
    }

    a {
      margin-top: 20px;
      color: #f1f1f1;
    }

    i {
      padding: 0 20px;
      font-size: 25px;
      line-height: 60px;
      cursor: pointer;
      float: right;

      &:hover {
        color: #2F2E32;
      }
    }
  }
}
