@font-face {
  font-family: 'Open Sans';
  src: url('./OpenSans-Regular.eot');
  src: url('./OpenSans-Regular.woff2') format('woff2'),
  url('./OpenSans-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./OpenSans-Semibold.eot');
  src: url('./OpenSans-Semibold.woff2') format('woff2'),
  url('./OpenSans-Semibold.woff') format('woff'),
  url('./OpenSans-Semibold.ttf') format('truetype'),
    url('./OpenSans-Semibold.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./OpenSans-Bold.eot');
  src: url('./OpenSans-Bold.woff2') format('woff2'),
  url('./OpenSans-Bold.woff') format('woff'),
  url('./OpenSans-Bold.ttf') format('truetype'),
  url('./OpenSans-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'Open Sans', 'Helvetica', sans-serif;
}