@import '../../../../style/variables.less';

tooltip {
  position: relative;

  &:hover tooltip-body {
    display: block;
    opacity: 1;
    transform: translate(-50%, 5px);
    visibility: visible;
  }

  &-body {
    background-color: white;
    border: 1px solid @reportBorderColor;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    color: @fontColor;
    opacity: 0;
    padding: 20px 30px;
    position: absolute;
    top: 100%; left: 50%;
    transition: 0.2s ease-in-out;
    transform: translate(-50%, 0);
    visibility: hidden;
    white-space: nowrap;
    z-index: 100;

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
    }

    &::after {
      top: -18px;
      border-color: transparent transparent white transparent;
      border-width: 10px;
    }

    &::before {
      top: -22px;
      border-color: transparent transparent @reportBorderColor transparent;
      border-width: 11px;
    }
  }
}
