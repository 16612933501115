#common-product-details() {
  form {
    margin: 0;
  }

  div.form-section {
    box-sizing: border-box;
    width: 500px;
    float: left;
    padding: 0 40px 0 20px;
  }

  select.charge-type {
    margin-bottom: 0;
  }

  .buttons {
    float: right;
    margin: 20px;
  }

}

#loan-product-details {
  #common-product-details();
  #loan-account-information-defaults {
    table{
      margin-left: 30px;
      background-color: transparent;
      td.labels {
        background-color: transparent;
      }
      td.values {
        background-color: transparent;
      }
    }
  }
}

// dragula styles needs to outside of the loan-product id scope :(
li.dragula-loans {
  padding: 5px;
  margin: 2px;
  background: white;
  list-style-type: none;
  cursor: default;
}

li.dragula-loans.ex-moved {
  // nothing
}
li.dragula-loans.ex-over {
  opacity: 0.5 !important;
}

#pawn-product-details, #layaway-product-details {
  #common-product-details();
}
