customer-term-deposit-cash-termination {

  label.checkbox {
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-top: 5px;

    input {
      vertical-align: bottom;
      position: relative;
      margin: 0;
      width: auto;
    }
  }
}
