#dashboard-collections-div {
  @import '~Styles/variables';

  .dashboard-collections__requirements-section {
    .form-section__title {
      border-bottom: none;
    }
  }

  .dashboard-collections__requirements-list {
    td:first-child {
      width: 30px;
    }

    .selectize-control {
      width: 220px;

      .selectize-input {
        display: block;
      }
    }

    select, input {
      margin-bottom: 0;
    }
  }

  .dashboard-collections__requirements-buttons {
    align-items: stretch;
    display: flex;
    justify-content: flex-end;
    margin: 0 -5px;

    > button {
      margin: 0 5px;
      width: 90px;
    }

    #btn-register-payment {
      width: 150px;
    }
  }

  .dashboard-collections__collections-section {
    .form-section__title {
      height: 20px;
      padding-top: 0;
    }

    .group-loan-header {
      font-weight: bold;
    }

    .table tbody+tbody {
      border-top-width: 1px;
    }

    td input {
      width: unset;
    }

    td > input, td glue-container {
      box-sizing: border-box;
      height: unset;
      min-width: 100px;
      width: 100%;
    }

    td.separator {
      background: transparent;
      font-weight: bold;
    }

    glue-container .transclude, glue-container input {
      box-sizing: border-box;
      height: auto;
      min-width: 100px;
      width: 100%;
    };

    .dashboard-collections__collections-status-check-button {
      background: none;
      border: none;
      padding: 0;
      margin-left: 3ch;
      width: auto;

      .collection-status-invalid {
        border-color: transparent;
        box-shadow: 0 0 6px 1px @invalidValidationColor;
      }
    }

    .dashboard-collections__collections-tr-input {
      margin-bottom: 0;
    }
  }

  .signatures {
    display: none;
    margin-top: 20px;

    table {
      width: 400px;

      th {
        text-align: left;
      }

      .underline{
        border-bottom: solid 1px black;
        margin-right: 40px;
        height: 40px;
        width: 300px;
      }
    }
  }

  @media print {

    .signatures {
      display: inherit;
    }

    .dashboard-collections__requirements-section {
      h3 {
        display: none;
      }

      input, select, .selectize-input, .selectize-input .item {
        border: none;
        padding-left: 0;
        margin-left: 0;
      }

      .selectize-input input {
        display: none !important;
      }

      select {
        // select has some weird inner padding. removing it
        margin-left: -3px;
        //-webkit-appearance: none;
      }

      td {
        padding-top: 1px;
        padding-bottom: 1px;
      }

      td.number {
        display: none;
      }

      tr:first-child td {
        border-top: none;
      }
    }

    .dashboard-collections__requirements-buttons {
      display: none;
    }

    .dashboard-collections__collections-section {
      h3 {
        border-bottom: none;
      }

      glue-container {
        .glue-appended {
          display: none;
        }
      }

      table tr td input {
        border: none;
        text-align: center;
        max-width: 50px;
      }
    }
  }

  .yellow-outline {
    box-shadow: 0 0 6px #e6e600;
    border-color: #e6e600;
  }
}