customer-loans-transfer-payment {

  .account-select {
    margin-top: 10px;
  }

  @media print {
    .form-section {
      .form-section__title {
        font-size: 24px;
        border-bottom: none;
      }
    }
    .no-print {
      display: none;
    }
  }
}