@import "~bootstrap/less/variables";

logged-in-users {
  .content {
    padding: 10px;

    .filters {
      display: flex;
      float: left;
      justify-content: flex-end;

      > * {
        margin: 5px;
      }

      .status {
        font-weight: bold;
      }

      button {
        height: 30px;
      }

      select {
        width: 140px;
      }
    }

    .table-content {
      overflow: visible;

      table {
        margin-bottom: 0px;
      }
    }
  }

  .logged-in-icon {
    color: @green;
  }

  .logged-out-icon {
    color: @red;
  }
}