.condition {
  background: #ededef;
  padding: 5px;
  margin: 5px;
  border: 1px solid lightgray;
  border-radius: 10px;

  .close {
    padding-left: 8px;
    align-self: center;
    font-size: 16px;

    &:after {
      content: 'X';
    }
  }
}
