@border-color: #ddd;
@row-height: 46px;

.products-table {
  margin: 0 20px;
  width: ~"calc(100% - 40px)";

  td {
    vertical-align: middle;
    padding: 0 8px 0 0;

    &:first-child {
      padding-left: 8px;
    }
  }

  th {
    padding-left: 0;
  }
}

.products-table__row {
  height: @row-height;
  background-color: white;
  border-top: 1px solid @border-color;
}

.products-table__item-action {
  box-sizing: border-box;
  width: 100px;
  margin-right: 2px;
  float: right;
}
