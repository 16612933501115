.customer-picker {
  .picker-content {
    border: 1px solid lightgray;
    padding: 10px;
    position: absolute;
    background-color: white;
    margin-top: 5px;

    max-height: 305px;
    overflow-y: auto;
    z-index: 999;

    table {
      a.btn {
        margin-left: 20px;
      }

      td {
        // override input-prepend...
        font-size: 14px;
      }

    }
  }

  .no-results {
    color: gray;
    text-shadow: 1px 1px 0 lightgrey;
    font-size: 15px;
    margin: 10px 15px;
  }
}