.appraisal-details {

  td.inline-panel-table-cell {
    padding: 0;
    position: absolute;
    right: 0;
    width: 40%;
    min-width: 600px;
    z-index: 99;
  }

  a.key-value-value {
    cursor: pointer;
  }
}
