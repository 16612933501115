#config_users_div button {
  margin-left: 0;
}

#config_users_div .modal-inner {
  padding: 30px 50px 20px 50px;
}

#config_users_div #ca_table_users {
  padding: 0 20px;
}

#config_users_div #ca_btn_new_user {
  margin-left: 20px;
}

#config_users_div .control-label {
  width: 250px;
}

.users-list__sub-header {
  background-color: #f8f8fa;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  height: 60px;
}

.users-list__sub-header-column-left-element {
  padding: 20px;
  border-right: 1px solid lightgray;
}

.users-list__sub-header-column-left {
  display: flex;
}

.users-list__sub-header-column-right {
  padding: 15px;
  width: auto;
  box-sizing: border-box;
  border-right: 1px solid lightgray;
}

.branches__add-new-deprecated {
  float: right;
  margin: 15px 20px;
  height: 30px;
  width: 100px;
}

.users-list-table {
  td, th {
    padding-left: 0;
  }
  .users-list__number-column {
    width: 40px;
    padding-left: 8px;
  }
  .active {
    color: green;
  }
  .blocked {
    color: red;
  }
}

.users-list__open-link-column {
  width: 100px;

  .open-link {
    box-sizing: border-box;
    width: 100%;
  }
}

.users-list__filter-field {
  display: inline-block;
  border: 1px solid #cccccc;
  border-radius: 4px;
  float: right;
  margin: 10px 20px 10px 0;

  label {
    display: inline-block;
    line-height: 30px;
    padding: 0 15px;
    vertical-align: top;
    font-weight: bold;
    margin: 0;
  }

  input,
  select {
    margin-bottom: 0;
    border: 0;
    border-radius: 0 4px 4px 0;
    outline: none;
    vertical-align: top;
    border-left: 1px solid #ccc;
  }
}

.user-details {
  .buttons {
    padding-right: 40px;
    text-align: right;
  }

  #expired-err {
    color: red;
    font-weight: bold;
  }
}
