stock-items-list {
  .content {
    padding: 20px;

    .filters {
      display: flex;
      flex-wrap: wrap;

      &__add-button {
        display: flex;
        margin-bottom: 20px;
      }
    }

    table {
      .tdcenter {
        text-align: center;
      }

      .actions-wrapper {
        display: flex;

        &__button {
          align-items: center;
        }
      }
    }
  }
}