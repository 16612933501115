@import "~bootstrap/less/variables";

customer-forex-operation {
  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  .td-no {
    width: 20px;
  }

  .td-label {
    width: 230px;
  }

  .others-input {
    margin-left: 10px;
  }

  .reset-margin {
    margin: 0;
  }

  .d-inline-block {
    &__field {
      display: inline-block;
      vertical-align: middle;
    }

    &__info {
      margin-left: 10px;
      color: @infoText;
    }
  }
}
