.group-loans-create-members {
  @import '~bootstrap/less/variables';

  .label-checkbox {
    display: inline-block;
    padding: 5px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin: 0 -5px;
    margin-bottom: 20px;

    & > * {
      margin: 0 5px;
    }
  }

  .member-configured {
    border-bottom: 3px solid @btnSuccessBackground;
  }
}