notification-schedule {
  table {
    td,th {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 15%;
      }
      &:last-child {
        text-align: right;
        padding-right: 35px;
      }
    }
  }

  .schedule-list__ {
    &add-new-column {
      text-align: right;
    }

    &input {
      margin-top: 10px;
      width: calc(100% - 5px);
    }
  }

  .sub-header__create-button {
    box-sizing: border-box;
    min-width: 100px;
    margin: 15px 8px;
    float: right;
  }
}
