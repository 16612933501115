@import '~bootstrap/less/variables';

// disable the scrollbar on windows (the content is still scrollable via mouse wheel)
.sidenav::-webkit-scrollbar {
  display: none;
}

.sidenav {
  position: fixed;
  left: 0px;
  top: 0px; /* same as .main-container top */
  bottom: 0px;
  width: 190px; /* .main-container left = 200px */
  background-color: #333237;
  z-index: 1020; /* 10 less than .navbar-fixed to prevent any overlap */
  color: #efedf0;
  padding-top: 1px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    padding: 10px 0 10px 14px;
    margin-right: auto;
  }

  button, .sidenav-section-header {
    padding: 5px 0 5px 20px;
    letter-spacing: 2px;
    font-size: 14px;
    /*font-weight: bold;*/
  }

  @media (max-height: 1000px) {
    button, .sidenav-section-header {
      padding: 3px 0 3px 15px;
      letter-spacing: 2px;
      font-size: 12px;
      /*font-weight: bold;*/
    }
  }


  .sidenav-section-header {
    color: #a5a3a6;
    text-transform: uppercase;
    background: #2F2E32;
    cursor: pointer;
  }

  i {
    padding-right: 2px
  }

  button {
    width: 100%;
    border: none;
    text-align: left;
    color: #eceaed;
    background: transparent;
  }

  button:hover {
    background: #2bc88e;
    color: white;
  }

  .sidenav-highlight {
    color: #2bc88e;
  }

  .sidenav-selected {
    background: #2bc88e;
    color: white;
  }

  .sidenav-selected:hover {
    background: #2bd79d;
  }

  #customer_close_icon {
    cursor: pointer;
  }

  #customer_refresh_icon {
    margin-left: 30px;
    cursor: pointer;
  }

  .sidenav-actions-button {
    position: relative;
  }

  .sidenav-actions-counter {
    margin-left: 10px;
  }

  .invalid-profile {
    float: right;
    color: @errorText;
  }

  .app-info {
    text-align: center;
    user-select: none;
    color: #534f61;
    display:flex;
    flex-direction: column;
  }

  .app-info__entry {
    margin-top: auto;
  }
}
