transactions-list {
  table {
    th {
      &.name {
        width: 30%;
      }
      &.code {
        width: 30%;
      }
      &.ledger {
        width: 40%;
      }
    }
  }

  #tab-cash-in {
    margin: 0 10px 20px;
  }

  #tab-cash-out {
    margin: 0 10px 20px;
  }
}