#customer-deposit-accounts-cash-deposit-div {

  .content {
    padding: 0 20px;
    max-width: 900px;

    .cash-amount-picker {
      margin-top: 20px;
    }

    .buttons {
      float: right;
      margin-top: 40px;
      margin-right: 40px;
    }

  }

}