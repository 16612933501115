transaction-history {

  .horizontal {
    display: flex;
    padding: 5px;
  }

  .status-REVERTED, .status-REJECTED, .status-ERROR {
    color: red;
  }

  .status-PROCESSED {
    color: green;
  }
}