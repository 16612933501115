loan-preview {
  display: block;

  .content-pane {
    margin-top: 20px;
    padding-bottom: 0;
  }

  table tr:first-child td {
    border-top: none;
  }

}