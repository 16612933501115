risk-category-details-view {
  @import '../../../../../../style/variables';

  form {
    margin: 0;
  }

  div.form-section {
    box-sizing: border-box;
    width: 500px;
    float: left;
    padding: 0 40px 0 20px;
  }

  .buttons {
    float: right;
    margin: 20px;
  }

  .risk-category-entry {

    &__table {
      box-sizing: border-box;
      margin: 20px;
      width: calc(100% - 40px);

      input, select {
        margin: 0;
      }

      input.input--small-number {
        max-width: 50px;
      }

      input.input--description {
        width: 300px;
      }

      select.select--risk-level {
        max-width: 100px;
      }

      .no-column-header {
        width: 40px;
      }

      th.th--description {
        width: 300px;
      }

      th.th--conditions {
        width: 50%;
      }

      td {
        .conditions {
          display: flex;
        }
      }

      td, th {
        &:last-child {
          width: 220px;
        }
      }
    }

    &__button {
      width: 80px;
    }
  }
}