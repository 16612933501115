interest-board {
  display: block;
  padding: 0 20px;

  div.alert {
    margin: 0;
  }

  p.title {
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid lightgray;
  }

  div.form-section {
    width: 400px;
  }
}
