#ata-relations {

  h2 {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }

  .ata-listing {
    margin-left: 0;
  }

  .ata-relation-boxes {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;

    .ata-relation-box {
      width: 100%;
      padding: 0 10px 10px;
    }
  }
}
