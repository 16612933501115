history-miscellaneous-transactions {
  .content {
      padding: 10px;
  }

  .filters {
    display: flex;
    float: right;
    justify-content: flex-end;

    > * {
      margin: 10px;
    }

    .status {
      font-weight: bold;
    }

    button {
      height: 30px;
    }
  }

  .centered-gray-information-message {
    z-index: -1;
  }
}
