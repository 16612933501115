@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Regular.eot');
  src: url('../fonts/OpenSans-Regular.woff2') format('woff2'), url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Semibold.eot');
  src: url('../fonts/OpenSans-Semibold.woff2') format('woff2'), url('../fonts/OpenSans-Semibold.woff') format('woff'), url('../fonts/OpenSans-Semibold.ttf') format('truetype'), url('../fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Bold.eot');
  src: url('../fonts/OpenSans-Bold.woff2') format('woff2'), url('../fonts/OpenSans-Bold.woff') format('woff'), url('../fonts/OpenSans-Bold.ttf') format('truetype'), url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}
body {
  font-family: 'Open Sans', 'Helvetica', sans-serif;
}
/*
body: #f7f7f9
sidebar: #333237
sidebar_dark: #2F2E32
form_gray: #ededef
selection_green: #2bc88e
*/
html {
  height: 100%;
  zoom: 75%;
}
body {
  background: #EDEDEF;
  padding-left: 190px;
  height: 100%;
}
menu {
  margin: 0;
}
.main-container > .container-fluid > .row-fluid {
  height: 100%;
}
.main-container > .container-fluid > .row-fluid > .span12 {
  height: 100%;
}
.main-container > .container-fluid > .row-fluid > .span12 > .main-view {
  height: 100%;
}
button,
button.btn {
  outline: none !important;
}
.content-pane {
  padding: 0 20px 20px 20px;
}
.centered-gray-information-message {
  position: fixed;
  left: 95px;
  top: 400px;
  bottom: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  text-shadow: 1px 1px 0 lightgrey;
  font-size: 20px;
  z-index: -1;
}
.form-horizontal .control-group {
  margin-bottom: 5px;
  margin-right: 10px;
}
.select2-container {
  width: 220px;
}
.select2-container .select2-arrow {
  padding-right: 0;
}
#gritter-notice-wrapper {
  z-index: 999999999999;
}
/* sticky footer */
.main-container {
  height: 100%;
  padding-top: 0px;
}
.bottom-bar {
  height: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: solid 1px lightgray;
  padding: 10px 0px 10px 20px;
  background-color: #f1f1f1;
  z-index: 99999;
  display: none;
}
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
  height: 100%;
}
.hover-lightgray:hover {
  background: #f4f4f6;
}
.tab-menu ul.nav > li > a {
  cursor: pointer;
}
.tab-content {
  padding: 0;
  overflow: inherit;
}
/* stretch menu to 100% width */
.primary-menu ul.nav-tabs {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: solid 1px lightgray;
  margin-bottom: 0px;
  background: #fafafa;
}
.primary-menu ul.nav-tabs li {
  display: table-cell;
  width: inherit;
  margin-bottom: 0px;
  float: none;
}
.primary-menu ul.nav-tabs li .icon-warning-sign {
  margin-right: 5px;
  color: red;
}
/* /stretch */
.primary-menu ul.nav-tabs a {
  text-align: center;
  text-transform: uppercase;
  color: black;
  background: transparent;
  padding-top: 10px;
  padding-bottom: 5px;
  /* padding top - border bottom */
  margin-right: 0px;
  border: none;
  border-radius: 0;
  outline: none;
}
.primary-menu ul.nav-tabs li.disabled a {
  color: lightgray;
}
.primary-menu ul.nav-tabs a:hover,
.primary-menu ul.nav-tabs a:focus {
  background: transparent;
}
.primary-menu ul.nav-tabs li.active a {
  color: #2c2c2c;
  margin-bottom: 0px;
  border: none;
  border-bottom: solid 5px #2bc88e;
}
.sub-menu ul.nav-tabs {
  border: none;
  margin-bottom: 0;
}
/* stretch menu to 100% width */
.sub-menu ul.nav-tabs {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.sub-menu ul.nav-tabs li {
  width: 33.333333%;
  /* 100 / number of elements */
  margin-bottom: 0px;
}
/* /stretch */
.sub-menu ul.nav-tabs a {
  text-align: center;
  color: #2bc88e;
  background: #f7f7f8;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 0px;
  border-radius: 0;
  outline: none;
  font-size: 22px;
  border-left: none;
  border-right: none;
  border-top: solid 1px lightgray;
  border-bottom: solid 1px lightgray;
}
.sub-menu ul.nav-tabs a:hover,
.sub-menu ul.nav-tabs a:focus {
  background: #fbfbfc;
  border-left: none;
  border-right: none;
  border-top: solid 1px lightgray;
  border-bottom: solid 1px lightgray;
}
.sub-menu ul.nav-tabs li.active a {
  color: #f5f5f5;
  background: #2bc88e;
  margin-bottom: 0px;
}
/** TABLE **/
table.table.table-style {
  background-color: transparent;
}
table.table.table-style td {
  background-color: white;
  vertical-align: middle;
}
table.table.table-style tr:hover > td {
  background-color: #CCFFEF;
}
table.table.table-style tr.selected > td {
  background-color: #2BD79D;
  font-weight: 600;
}
table.table.table-style button {
  width: 100px;
}
table.table.table-style .buttons {
  text-align: right;
}
table.table.table__generate_row_ids {
  counter-reset: misc-transaction-table;
}
table.table.table__generate_row_ids tbody tr td:first-child:before {
  counter-increment: misc-transaction-table;
  content: counter(misc-transaction-table) ".";
}
.modal-inner {
  margin-top: 50px;
  padding: 50px 50px 20px 50px;
  border: solid 1px darkgray;
  background: white;
  display: inline-block;
  max-height: 80%;
  overflow-y: auto;
}
.modal-maximized {
  padding: 0px;
  margin: 20px;
  height: 90%;
  overflow-y: auto;
}
td.buttons button {
  margin: 0;
}
td.buttons2 {
  width: 205px;
  /* 2 buttons * 100px */
}
td.buttons3 {
  width: 308px;
  /* 3 buttons * 100px */
}
.btn-selected {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #2bc88e;
  /*#2bc88e*/
  *background-color: #23976b;
  background-image: -moz-linear-gradient(top, #2bc88e, #23976b);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#2bc88e), to(#23976b));
  background-image: -webkit-linear-gradient(top, #2bc88e, #23976b);
  background-image: -o-linear-gradient(top, #2bc88e, #23976b);
  background-image: linear-gradient(to bottom, #2bc88e, #23976b);
  background-repeat: repeat-x;
  border-color: #23976b #23976b #23895e;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
.btn-selected:hover,
.btn-selected:focus,
.btn-selected:active,
.btn-selected.active,
.btn-selected.disabled,
.btn-selected[disabled] {
  color: #ffffff;
  background-color: #23976b;
  *background-color: #23976b;
}
.btn-selected:active,
.btn-selected.active {
  background-color: #23895e                   \9;
}
.btn-group a {
  cursor: default;
}
.core-modal h2 {
  margin-bottom: 40px;
}
.core-modal .sub-header {
  margin-bottom: 50px;
}
.core-modal .bottom-buttons {
  margin-top: 10px;
}
.hidden-element {
  border: 0px;
  width: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;
  outline: none;
  opacity: 0 !important;
}
h2.white-header {
  background: white;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0;
  border-top: none;
  border-bottom: 1px solid lightgray;
}
.horizontal-padding {
  padding: 0 20px;
}
/*
 Div with "Operations:" label and action buttons
*/
.operations-div {
  background: white;
  border: 1px solid lightgray;
  margin-top: 20px;
  padding: 10px;
}
.operations-div span {
  padding-right: 5px;
  font-weight: bold;
}
.green {
  color: green;
}
.red {
  color: red;
}
.blue {
  color: blue;
}
.orange {
  color: orange;
}
input.ng-invalid,
select.ng-invalid,
textarea.ng-invalid,
cash.ng-invalid input,
date.ng-invalid input,
form.ng-submitted input.ng-invalid,
[ng-form].ng-submitted input.ng-invalid,
form.ng-submitted select.ng-invalid,
[ng-form].ng-submitted select.ng-invalid,
form.ng-submitted textarea.ng-invalid,
[ng-form].ng-submitted textarea.ng-invalid,
form.ng-submitted cash.ng-invalid input,
[ng-form].ng-submitted cash.ng-invalid input,
form.ng-submitted date.ng-invalid input,
[ng-form].ng-submitted date.ng-invalid input,
file-upload.ng-invalid.ng-invalid-required #file-upload-div {
  border-color: transparent;
  box-shadow: 0 0 6px #DD0000;
}
.spinner {
  font-size: 20px;
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bold {
  font-weight: bold;
}
menu button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.CodeMirror {
  zoom: 133%;
}
.CodeMirror-hints {
  zoom: 133%;
}
/*
  .container from bootstrap sets max-width to 1170px and does autocentering, we only want width
 */
.nx-container {
  max-width: 1170px;
}
/*
  Primarily used for buttons at the bottom of the page.
 */
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
}
.flex-container {
  display: flex;
}
.summary-actions-child,
.summary-actions > * {
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.summary-actions {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  margin: 0 -3px;
}
