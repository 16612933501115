customer-access {
  .flex-container {
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 0;

    & > * {
      align-items: center;
      display: flex;
      margin: 0 5px;
    }
  }

  .button-container {
    & > * {
      margin: 0 3px;
    }
  }
}