forex-panel {

  .forex-panel__header {
    box-sizing: border-box;
    margin: 10px 10px 10px 10px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 24px;
    padding-top: 23px;
    border-bottom: 1px solid #ccc;
  }

  .transacting-state__organization-bank-select {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding-left: 5px;
  }

  .transacting-state__transaction {
    padding-left: 5px;
  }

  .message-bar {
    padding: 15px 10px;
  }

  .buttons {
    display: flex;
    flex-direction: row-reverse;

    button {
      margin-right: 5px;
    }

  }

}
