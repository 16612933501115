#loan-account-information {
  table.table.table-style {
    td.mis-group {
      vertical-align: top;
    }

    td.labels.collaterals {
      vertical-align: top;
    }

    td.values {
      padding-right: 193px;

      .mis-tree {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        input {
          width: 300px;
        }

        & > * {
          margin-right: 10px;
        }

        thead {
          display: none;
        }

        tr.level-1 td {
          border: none;
        }

        td {
          padding: 1px;
        }

        td:last-child {
          display: none;
        }
      }
    }
  }
}