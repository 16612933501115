#file-upload-div {
    margin: 5px;

    figure {
        margin: 0;
    }

    .file-upload__list {
        margin: 0;
        margin-bottom: 15px;
    }

    .file-upload__list-item {
        align-items: center;
        display: flex;
        list-style: none;
        margin: 0 -10px;
        padding: 10px 0;

        > * {
            margin: 0 10px;
        }
    }

    .file-upload__image {
        box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.5);
        width: 100px;
        text-align: center;
    }

    .file-upload__thumbnail {
        max-width: 90px;
        max-height: 50px;
        margin: 5px;
    }

    .file-upload__caption {
        margin: 5px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .file-upload__progressbar-value {
        background: #2bc88e;
        height: 5px;
    }

    .file-upload__input-container {
        display: flex;
        align-items: flex-end;

        label {
          margin-bottom: 0;
          margin-right: 5px;

        }
    }
}

#file-upload-new-div {
    .file-upload-new__container > *[disabled=disabled]{
      opacity: 0.5;
    }

    .file-upload-new__container {
        display: flex;
        margin: 0 -10px;

        > * {
            margin: 0 10px;
        }

        .file-upload-new__drop-zone {
            align-items: center;
            border: 5px dashed #dddddd;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            padding: 5px;
            min-height: 40px;
            min-width: 100px;
        }

        .file-upload-new__select-file {

        }
    }

    .file-upload-new__webcam-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .file-upload-new__webcam-action-button {
        position: absolute;
        bottom: 40px;
    }
}


#file-upload-webcam-div {
    .modal {
        left: 15%;
        margin: 0;
        width: 70%;
    }

    .file-upload-webcam__container {
        align-items: center;
        display: flex;
        flex-direction: column;
        max-height: 100vh;

        video {
            min-height: 80vh;
            min-width: 80vw;
        }
    }

    .file-upload-webcam__action-button {
        position: absolute;
        bottom: 40px;
    }
}
