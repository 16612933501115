.attendance-menu {
  @light-gray: #fafafa;
  @border-color: lightgray;

  font-size: 0;
  margin: 0;
  border-bottom: 1px solid @border-color;
  background: @light-gray;

  &__item {
    @profile-config-menu-height: 40px;
    @green: #2bc88e;

    box-sizing: border-box;
    font-size: 14px;
    text-transform: uppercase;
    list-style: none;
    display: inline-block;
    width: 25%;
    height: @profile-config-menu-height;
    line-height: @profile-config-menu-height;
    text-align: center;
    cursor: pointer;

    &.inactive {
      color: @border-color;
      cursor: not-allowed;
    }

    &.active {
      border-bottom: 5px solid @green;
    }

    i {
      color: red;
    }
  }
}

.clock-in-out {
  .content {
    padding: 0 20px;
    max-width: 100%;
  }
}

.attendance-filter {
  span {
    display: inline-block;
    margin: 5px 0 0 0;
  }
}
