#inventory-checks-list {

  .nav-tabs {
    .disabled {
      color: darkgray;
    }
  }

  .content {
    padding: 10px;

    .actions {
      display: flex;
      float: left;
      justify-content: flex-start;

      > * {
        margin: 10px;
      }

      button {
        height: 30px;
      }
    }

    .filters {
      display: flex;
      float: right;
      justify-content: flex-end;

      > * {
        margin: 10px;
      }

      .status {
        font-weight: bold;
      }

      button {
        height: 30px;
      }
    }

    .tab-content {
      overflow: visible;

    }
  }
}