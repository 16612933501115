force-profile-update-view {
  form {
    margin: 0;
  }

  div.form-section {
    box-sizing: border-box;
    width: 500px;
    float: left;
    padding: 0 40px 0 20px;
  }

  div.form-section-field {
    margin-bottom: 20px;
  }

  .buttons {
    float: right;
    margin: 20px;
  }
}