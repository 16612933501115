check-form {

  .selectize-input {
    width: 100%;
  }

  .checkbox-column {
    border: none;
    text-align: center;
    margin-bottom: 0;
    max-width: 100px;

    input {
      width: 15px;
    }
  }

  table {
    margin-top: 20px;

    input {
      margin-bottom: 0;
      width: 100%;
    }

    select {
      width: 100%;
      margin: 0;
    }
  }

}