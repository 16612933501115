#customer-deposit-accounts-check-deposit-div, #customer-deposit-accounts-check-withdrawal-div{

  .content {
    margin: 0 20px;
    max-width: 900px;

   .buttons {
      float: right;
      margin-top: 20px;
    }
  }

  #recalculate-tax-btn {
    height: 30px;
    width: auto;
  }

  .check-no {
    height: 30px;
    width: 100%;
  }

  td {
    &:nth-child(2) {
      width: 25%;
    }
  }
}