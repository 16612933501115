.misc-transaction {
    .iframe-preview {
      flex: 1 1 auto;
      position: absolute;
      width: 0px;
      height: 0px;
    }
  
    .iframe-invisible {
      height: 0;
      width: 0;
    }
  
    .report-content {
      box-sizing: border-box;
      margin: 70px 20px 20px;
      background-color: #fff;
  
      .header-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: large;
        font-weight: 640;
        letter-spacing: 0.04px;
        margin-bottom: 2rem;
        padding: 16px;
  
        .header-label {
          font-family: 'Times New Roman', Times, serif;
          padding: 6px;
          width: 100%;
          text-align: center;
        }
      }
  
      .main-report-container {
        border: none;
        margin: 0 0 20px;
        background-color: #fff;
        width: 96%;
        padding: 36px;
      }
  
      .tables-container {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
  
      .tables-content-container {
      
        display: flex;
        flex: 1;
      }
  
      .table-item {
        width: 33.333%;
      }
  
      table.top-header {
        padding: 8px 0px 8px 0px;
        font-weight:600;
  
        th.variance {
          border-right: 1px solid #000;
        }
      }
  
      table {
  
        th {
          font-weight:600;
          width: 33.333%;
        }
  
        th,
        td {
          border: 1px solid #000;
          border-right: none;
          font-size: 12px;
          letter-spacing: 0.02px;
          text-align: center;
          padding: 8px;
        }
  
        th.variance-total, td.variance-total {
          border: 1px solid #000;
        }
  
        .actions-wrapper {
          display: flex;
  
          &__button {
            align-items: center;
          }
        }
      }
  
      table.top-header {
        margin-bottom: 0px;
        th {
          font-size: 14px;
          font-weight:600;
          padding: 8px;
        }
      }
  
      table.data-table {
        th {
          border-bottom: none;
          border-top: none;
          padding: 8px;
          width: 11.111%;
        }
      }
  
      .table-content-container {
        border-bottom: 1px solid black;
      }

      tfoot.footer-content {
        td {
          border: none;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .footer-border {
        border-bottom: 1px solid black;
        margin-bottom: 3px;
      }
  
      .signatures {
        padding-top: 20px;
        margin-top: 40px;
  
        table.signature {
          width: 100%;
          border: none;
  
          th.signature,
          td.signature {
            border: none;
            font-size: 14px;
            text-align: left;
          }
  
          .underline {
            font-size: 14px;
            border-bottom: 1px solid black;
            margin-right: 40px;
            height: 40px;
            width: 320px;
            
            .content {
              text-align: center;
              padding: 15px 0 0 0;
            }
          }
        }
      }
    }
  }