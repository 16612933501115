stock-item-sell-view {
  .content {
    padding: 0 20px;
  }

  .select-customer-container {
    input {
      margin-bottom: 0;
    }
  }
}