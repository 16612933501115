glue-container {
  .input-append, .input-prepend {
    display: flex;
    align-content: flex-start;
    margin: 0;

    .glue-prepended {
      //margin: 9px 0;
      min-width: inherit;
      border-right: none;
    }

    .glue-appended {
      //margin: 9px 0;
      min-width: inherit;
      border-left: none;
    }

  }

}