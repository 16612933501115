#customer-deposit-accounts-create-div {
  .content {
    margin: 20px;
  }

  table.table-style {

    td.number {
      width: 20px;
    }

    td.buttons {
      text-align: right;
    }
  }
}
