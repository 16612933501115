.form__title {
  border-bottom: 1px solid lightgray;
  width: 65%;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  margin-bottom: 8px;
}

.form__content {
  box-sizing: border-box;
  margin: 30px 20px 20px;
}