.profile-body .tab-menu ul.nav {
  margin-bottom: 0px;
}

.profile-body .tab-content {
  border-bottom: 1px solid lightgray;
  padding: 0 0 20px 0;

  .form-header {
    color: darkgray;
    text-align: right;
    border-bottom: solid 1px lightgray;
    margin: 20px 20px 0 20px;
    padding-right: 10px;
  }
}

.profile-body hr {
  margin: 0;
  border: none;
  border-top: solid 1px lightgray;
}

.profile-body form {
  margin-bottom: 0;
  padding-top: 0;
}

.profile-body .add-button {
  margin-left: 20px;
  margin-top: 55px;
}

.profile-body .remove-button {
  width: 220px;
}

#cpr_tab_documents .doc-form {
  min-height: 200px;
}

#cpr_tab_income hr {
  margin-top: 2px;
  margin-bottom: 30px;
}

#cpr_tab_income .source-of-income {
  width: 100%;
}

/*#cpr_tab_income */
#cpr_inc_other_description {
  width: 607px;
}

#cpr_tab_income .source-of-income .remove-source {
  width: 220px;
}

#cpr_tab_income .template {
  display: none;
}

#cpr_edit_footer {
  margin: 20px 40px 0 0;
}

#cpr_tab_related_people {
  padding-top: 0;
}

.profile-related-people {
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;

  input, select {
    margin-bottom: 0;
  }
  
  select {
    width: auto;
  }

  input[disabled] {
    border: none;
    outline: 0;
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .vertical-center {
    padding: 4px;
  }

  td {
    padding: 5px 10px;
  }
}

div.horizontal {
  .input-append .add-on  {
    height: 19px;
    width: 50%;
    text-align: left;
  }  
}

.right-margin .half-size {
  .input-prepend .add-on  {
    height: 19px;
  }  
}

.profile-date-component {
  width: 50%;
}
