#popupModal {
  .modal-footer {
    text-align: left;

    .fine-print {
      color: darkgray;
    }

    .modalButton {
      float: right;
    }

  }
}