.report-filter {
  @import '../../../../style/variables.less';

  @media print {
    input {
      font-size: @fontPrintSize !important;
    }

    label {
      font-size: @fontPrintSize !important;
    }

    select {
      font-size: @fontPrintSize !important;
    }
  }

  .filter-value:not(.ng-invalid) {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0);
    transition: box-shadow 0.5s ease-in-out;
  }

  .autofilled-with-default {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  }
}
